/**
 *
 * Store as an initial local store for the app
 *
 * All the data that is needed accross the app like user, openProperties etc goes from here.
 *
 */

import React, { useEffect, useState, createContext, useReducer } from 'react';

import FirebaseUtils from './utils/firebase.utils';
import ApiUtils from './utils/api.utils.js';

import useDeviceDetect from './hooks/device-detect';
import { Departments, Settings,Branches } from './models';

// import { initializeFirebasePushNotification } from './push-notification';

/**
 * Initial State to be stored
 * accross the app
 *
 * For now maintaining only user data
 *
 * #todo Compare the setup with state manager utility
 *
 */
const initialState = {
    defaultBranch: {},
};

/**
 * Context for sharing state accross app
 */
export const GlobalContext = createContext(initialState);

/**
 *  Provider links the reducer and context
 */
export const GlobalProvider = ({ children }) => {

    const [branches, setBranches] = useState([]);

    const [disableDisplayPhoneNumber, setDisableDisplayPhoneNumber] = useState([]);

    const [state, dispatch] = useReducer(AppReducer, initialState);

    const { isMobile } = useDeviceDetect();

    useEffect(() => {
        FirebaseUtils.connect().then((auth, database) => {
            FirebaseUtils.getAuthStatus()
                .then((result) => {
                    if (result) {

                        if (result && result.email) {
                            // initializeFirebasePushNotification(result);
                        }

                        let userInfo = {
                            dms: {},
                            locations: [],
                            ...result,
                            ...{ loggedCheckDone: true },
                        };

                        // Get the branches and set as default according to user role
                        Branches.getAllBranches().then((res)=>{
                        setBranches(res)
                      })

                        // if (userInfo.department_ids) {
                        //     Departments.get().then((result) => {
                        // let match = result.departments.find(
                        //     (ele) => ele.location === userInfo.locations[0] && userInfo.department_ids.includes(ele.id)
                        // );

                        // userInfo.branch = match;

                        // let userLocations = result.departments.filter(
                        //     (item) => userInfo.locations.indexOf(item.location) !== -1 && userInfo.department_ids.includes(item.id)
                        // );

                        // let defaultBranch;

                        // if (match) {
                        //     defaultBranch = match;
                        // } else {
                        //     defaultBranch = result.departments[0];
                        // }
                        ApiUtils.setBranch({}, userInfo);

                        // ApiUtils.setBranches(result.departments);

                        // setdefaultBranch(defaultBranch);

                        // setBranches(userLocations);

                        // dispatch({ type: 'defaultBranch', payload: defaultBranch });

                        dispatch({ type: 'user', payload: userInfo });

                        localStorage.setItem('userInfo', JSON.stringify(userInfo));
                        // });
                        // }

                    } else {
                        dispatch({ type: 'user', payload: { ...{ loggedCheckDone: true } } });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });

        return () => { };
    }, []);

    // these roles would not have access to the customers phone number .
    Settings.getDisableDisplayPhoneNumber().then((res) => {
        var roles = res.indexOf(state.user.role) !== -1;
        console.log('DisableDisplayPhoneNumber', roles)
        setDisableDisplayPhoneNumber(roles)
    })

    // Store that is accessible accross the app
    // let store = { user: state.user, slot: state.slot, dispatch: dispatch, selectedLocation: state.selectedLocation }
    let store = { user: state.user, dispatch: dispatch, isMobile,branches:branches, disableDisplayPhoneNumber: disableDisplayPhoneNumber };

    return <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>;
};

/**
 * Reducer for managing the state
 *
 * @param state
 * @param action
 */
export const AppReducer = (state, action) => {

    console.log("APPPPPPPPPPP",state)
    switch (action.type) {
        case 'user':
            return { ...state, user: action.payload };

        case 'slot':
            return { ...state, user: action.payload };
         case 'branches':
            return { ...state,branches:action.payload};
        case 'disableDisplayPhoneNumber':
            return { ...state, disableDisplayPhoneNumber: action.payload };

        case 'selectedLocation':
            localStorage.selectedLocation = JSON.stringify(action.payload);
            return { ...state, selectedLocation: action.payload };

        default:
            return state;
    }
};
