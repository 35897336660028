import React, { useState, useEffect, useMemo } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { Link } from 'react-router-dom';

import { message ,Space} from 'antd';

import { Tag, Badge } from 'antd';

import moment from 'moment';

import { Bookings, StockTransactions, StockTransfers, Vehicles } from '../../models';
import {  cityKeys ,dealerCodeKey} from '../global-config';

var cityCode = cityKeys

function CancelCard({ request = {}, index, step }) {
    let city = dealerCodeKey[request.dealerCode]

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customerName}</h2>
                <h4 className="title">Phone No : {request.phone}</h4>
                <h3 className="title ">Type : {request.type}</h3>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h4>{request.remarks}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.approve_at}
            </div>
        </Link>
    );
}

function BlockedCard({ request = {}, index, step }) {
    let city = dealerCodeKey[request.dealerCode]
    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">Phone No : {request.customer.phone}</h4>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h3 className="title ">Variant: : {request.variant}</h3>
                <h4>{request.color}</h4>
                <h4>{request.customer.vinNo}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.allocation.created_at}
            </div>
        </Link>
    );
}

function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

function DeliveryScheduleCard({ request, index, step }) {
    return (
        <Link className="report-item" to={`${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount">
                    {/* {vehicle.delivery.scheduled_delivery_date} {vehicle.delivery.scheduled_delivery_time} */}
                    {moment(request.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A')}
                </h2>
                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}
                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}
                <h3 className="title">{request.customer.customerName}</h3>
                <h2 className="title">{request.customer.phone}</h2>
                <h3 className="title">
                    {request['variant']} - {request['color']}
                </h3>
                <p className="address">
                    KEC {request.kec} , {request.created_at}
                </p>
                <p>Delivery scheduled on : {moment(request.delivery.scheduled_time).format('DD/MM/YYYY  HH:mm A')}</p>
                Delivery Note :{' '}
                <Tag color="magenta"> {request.delivery.note && request.delivery.note.length ? 'Delivery Note Taken' : 'Pending'}</Tag>
                Settlement :{' '}
                <Tag color={request.delivery.settlementTaken ? 'green' : 'red'}> {request.delivery.settlementTaken ? 'Ready' : 'Pending'}</Tag>
            </div>
        </Link>
    );
}


/**
 * Steps for CCM
 */
export const ccmSteps = [
    //
    {
        caption: 'On Approve for Cancellation',
        path: '/cancellation-approval',
        redirect_to: 'cancellation-approval-form',
        model: 'cancellations',
        queries: [
            {
                field: 'status',
                value: 'CCM Approval Pending',
            },
        ],
        Card: CancelCard,
    },
    //

    //Active Allocation
    {
        caption: 'Active Allocations',
        path: '/active-allocations',
        redirect_to: 'active-allocation-form',
        model: 'requests',

        queries: ({ user }) => {
            return [
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Allocated'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    //Allocation Form
    {
        isForm: true,
        caption: 'Follow up for a quick retail',
        path: '/active-allocation-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },
    //
    {
        caption: 'Refund Initiated',
        path: '/refund-initiated',

        redirect_to: 'refund-initiated-form',
        model: 'cancellations',
        queries: [
            {
                field: 'status',
                value: 'Refund Confirmation Pending',
            },
        ],
        Card: CancelCard,
    },
    //

    {
        caption: 'Blocked Vehicle List',
        path: '/blocked-list',
        redirect_to: 'blocked-vehicle-form',
        model: 'requests',

        queries: [
            {
                field: 'allocation.status',
                operator:"in",
                value: ['Allocated','approved'],
            },
            {
                field: 'ccm.status',
                value: 'pending',
            },
            {
                field:'actual_billing.status',
                value:'pending'
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],

        Card: BlockedCard,
    },

    {
        isForm: true,
        caption: 'Blocked Vehicle Form',
        path: '/blocked-vehicle-form/:city/:id',
        description: 'Please inform the customer regarding funds and update remarks',
        model: 'ccm',
        endpoint: 'requests',
        post_status: 'completed',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },

    // Cancel Form
    {
        isForm: true,
        caption: 'Cancellation Approval Form',
        path: '/cancellation-approval-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'ccm_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                ccm_followed_up_at: moment().format('DD/MM/YYYY HH:mm'),
                ccm_followed_up_date: moment().startOf('day').valueOf(),
                ccm_followed_up_time: moment().valueOf(),
                status: 'Refund Approval Pending',
                ccm_followed_up_by: user.name,
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: 'Refund Approval Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },

    // Cancel Form
    {
        isForm: true,
        caption: 'Refund Initiated Form',
        path: '/refund-initiated-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'refund_initiated_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                ccm_confirmed_at: moment().format('DD/MM/YYYY HH:mm'),
                ccm_confirmed_date: moment().startOf('day').valueOf(),
                ccm_confirmed_time: moment().valueOf(),
                status: 'DMS Cancellation Pending',
                ccm_confirmed_by: user.name,
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: 'DMS Cancellation Pending',
                },
                isCancelled: true,
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },


    // Delivery Pending Cases
    {
        caption: 'Delivery Pending',
        path: '/delivery-pending-cases',
        redirect_to: 'delivery-pending-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'actual_delivery.status',
                    operator: 'in',
                    value: ['pending', 'Settlement Pending', 'Settlement Ready', 'Delivery Pending'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'Invoiced'
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

      // Delivery Pending Form
      {
        isForm: true,
        caption: 'Follow up for a quick delivery',
        path: '/delivery-pending-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // upcoming Delivery
    {
        // caption: 'Scheduled Delivery',
        // submenus: [
        //     {
        path: '/scheduled-delivery',
        caption: 'Upcoming Delivery',
        //status: 'Login',
        redirect_to: 'delivery-checklist',
        queries: [
            {
                field: 'delivery.schedule',
                value: 'Delivery Scheduled',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: DeliveryScheduleCard,
        columns: [
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => index + 1,
                disableExport: true
            },
            {
                title: 'Name',
                dataIndex: 'Name',

                render: (record, item, index) => {
                    return item.customer.customerName;

                },
                //For download data
                exportDefinition: (record) => {

                    return record.customer.customerName;
                },
            },
            {
                title: 'Scheduled Delivery Time',
                dataIndex: 'scheduled_delivery_time',

                render: (record, item, index) => {
                    return moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A');

                },

                //For download data
                exportDefinition: (record) => {
                    return moment(record.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A');
                },
            },
            {
                title: 'Customer Phone',
                dataIndex: 'phone',

                render: (record, item, index) => {
                    return item.customer.phone
                },
                //For download data
                exportDefinition: (record) => {
                    return record.customer.phone;
                },
            },
            {
                title: "Vin Number",
                dataIndex: "Vin Number",
                render: (record, item, index) => {
                    return item.allocation.vinNo
                },
                //For download data
                exportDefinition: (record) => {
                    return record.allocation.vinNo;
                },
            },
            {
                title: 'Action',
                key: 'action',
                disableExport: true,
                render: (text, item, index) => {
                    return (
                        <Space size="middle">
                            <Link to={`delivery-checklist/${item.city}/${item.id}`}>View</Link>
                        </Space>
                    );
                },

            }
        ]
    },
    // Delivery Checklist
    {
        isForm: true,
        model: 'delivery',
        caption: 'Delivery Checklist',
        path: '/delivery-checklist/:city/:id',
       

        // post_status: 'Delivered',
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Congrats , Vehicle Delivered',
                body: `Delivery Completed for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },
        extraParams: (userRequest, params) => {
            let creation = {
                actual_delivery_at: moment().format('DD/MM/YYYY HH:mm'),
                actual_delivery_date: moment().startOf('day').valueOf(),
                actual_delivery_time: moment().valueOf(),
            };

            return {
                delivery: {
                    ...creation,
                    ...params,
                    schedule: 'Delivery Completed',
                    status: 'Delivery Completed',
                    process: 'complete',
                },
                actual_delivery: {
                    ...userRequest.actual_delivery,
                    ...creation,
                    status: 'Delivery Completed',
                    process: 'complete',
                },
            };
        },

       
       
    },

];
