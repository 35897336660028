/**
 * Function to change the KEC from one to another for bulk selection
 * @author Anupama
 */

import React, { useEffect, useState, useContext } from 'react';

import { Select, Col, Row, Typography, Button, Form, Table, Space, Popconfirm, message, Menu, Dropdown } from 'antd';

import { Designations, Employees, Bookings, Requests, Enquirys, DocumentLogs } from '../../../../models';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import SELECT from 'react-select';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import './bulk-kec-change.scss';

const { Option } = Select;

const { Title } = Typography;

const layout = {
    layout: 'vertical',
};

export default function BulkKecChange() {

    const [record, setRecord] = useState([]);

    const [loading, setLoading] = useState(false);

    const [type, setType] = useState('Booking');

    const [kec, setKec] = useState([]);

    const [toKec, setToKec] = useState();

    const [toKecName, setToKecName] = useState();

    const [fromKec, setFromKec] = useState();

    const { user } = useContext(GlobalContext);

    const [form] = Form.useForm();

    const [btnloading, setBtnLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [selectedRows, setSelectedRow] = useState([]);



    const modeOptions = [
        { value: 'Booking' },
        { value: 'Enquiry' },
    ];

    /**
    *  Action for select Booking or Enquiry
    *
    * @returns
    */
    function handleClick(e) {
        setRecord([])
        form.setFieldsValue({ from_kec: null });
        setType(e);
    }

    const onSelectChange = (keys, record) => {

        setSelectedRowKeys(keys);

        setSelectedRow(record);
    };

    useEffect(() => {

        getEmployee();

    }, []);

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    /*
     ** To get the employees with designation as KEC
     */
    async function getEmployee() {

        return await Employees.get().then((result) => {

            var query = [
                {
                    field: 'designation',
                    value: 'KEC',
                },
            ];

            return Designations.get(query).then((res) => {
                var kecList = result.employees.filter((ele) => ele.designation === res.designations[0].id);


                //Preparing data for react-select
                let b = Object.keys(kecList).map((employee) => {
                    return { label: kecList[employee].fname + ' ' + kecList[employee].lname, value: kecList[employee].id };
                });

                setKec(b);

                return result.employees;
            });
        });
    }

    /*
     ** To change the current kec to new kec
     */
    async function changeKec() {
        setBtnLoading(true);

        // Fetch the selected KEC record
        let selectedkec = await Employees.getRecord(toKec);

        var app = FirebaseUtils.getApp();

        if (type === 'Booking') {
            const batch = app.batch(); // Single batch for Booking type

            await Promise.all(
                selectedRows.map(async (item) => {
                    let query = [
                        {
                            field: 'bookingNo',
                            value: item.id,
                        },
                        {
                            field: 'delivery.status',
                            value: 'pending',
                        },
                    ];

                    let userRequest = await Requests.get(query);
                    await Employees.changeKec(selectedkec, item, user, userRequest, batch);
                })
            );

            // Commit the batch once all operations are added
            await batch.commit();
        } else {
            // Handle Enquiries type
            await Promise.all(
                selectedRows.map(async (enquiry) => {
                    // Create a new batch for each enquiry
                    const batch = app.batch();
                    var params = {
                        consultant_id: selectedkec.id,
                        teamleader_id: selectedkec.header_id,
                        'Consultant Name': toKecName,
                    };

                    const enquiryRef = Enquirys.getRecordReference(enquiry.id);

                    // Add the enquiry update to the batch
                    await batch.update(enquiryRef, params);

                    // Record the log in a separate operation
                    await DocumentLogs.recordLog('enquiries', enquiry, params, user, batch, enquiryRef);
                })
            );
        }

        // Display success message
        await message.success(`KEC and Teamleader of these selected ${type} are updated successfully`);

        setBtnLoading(false);

        // Reload the window
        return window.location.reload();
    }


    /*
     * To list the data of the selected KEC
     */
    async function listing(kecid) {

        setFromKec(kecid.value);

        setLoading(true);

        var queries = [], bookingResult, bookingRec;

        if (type === 'Booking') {

            queries = [
                {
                    field: 'consultant_id',
                    value: kecid.value,
                },
                {
                    field: 'delivery.status',
                    value: 'pending',
                },
            ];
            // To get bookings corresponding to the queries
            bookingResult = await Bookings.get(queries);

            bookingRec = bookingResult.bookings;
        } else {

            queries = [
                {
                    field: 'consultant_id',
                    value: kecid.value,
                },
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },
            ];
            // To get Enquirys corresponding to the queries
            bookingResult = await Enquirys.get(queries);

            bookingRec = bookingResult.enquiries;
        }

        // Getting the consultant name and teamleader name and displaying in the table
        let result = await Promise.all(bookingRec.map(async (ele) => {

            if (ele.consultant_id) {

                // To get the consultant name of the corresponding record using its consultant_id
                let kecRec = await Employees.getRecord(ele.consultant_id);

                let kecName = kecRec.fname + ' ' + kecRec.lname;

                let teamLeaderId;

                if (kecRec.header_id.value) {

                    teamLeaderId = kecRec.header_id.value;

                } else {

                    teamLeaderId = kecRec.header_id;
                }

                // To get the teamleader name of the corresponding record using the consultant's header_id
                let teamLeaderRec = await Employees.getRecord(teamLeaderId);

                let teamLeaderName = teamLeaderRec.fname + ' ' + teamLeaderRec.lname;

                ele = {
                    ...ele,
                    consultant: kecName,
                    teamleader: teamLeaderName,
                };

                return ele;
            } else {
                return ele;
            }
        })
        );
        setRecord(result);

        // To set result as record for displaying it in the table

        setLoading(false);
    }

    /*
     ** To get the kec to which the selected record is assigned
     */
    async function getToKec(kecid) {
        if (kecid) {

            setToKecName(kecid.label)
            setToKec(kecid.value);

        } else {

            setToKecName()
            setToKec();
        }
    }


    /*
     ** To get the table in selection mode and using this selection to change the kec from one to another in bulk form
     */
    function TableView({ data, selectedRowKeys, onSelectChange }) {

        const rowSelection = {

            selectedRowKeys,

            onChange: onSelectChange,
        };

        var columns = [
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            },
            {
                title: 'Dealer Code',
                dataIndex: 'Dealer Code',
                key: 'Dealer Code',
            },
            {
                title: 'Customer Name',
                dataIndex: 'Name of the Customer',
                key: 'name',
            },
            {
                title: 'Phone',
                dataIndex: 'Contact Number',
                key: 'Contact Number',
            },
            {
                title: 'Consultant Name',
                dataIndex: 'consultant',
                key: 'Consultant Name',
            },
            {
                title: 'Team Leader',
                dataIndex: 'teamleader',
                key: 'Team Leader',
            },
        ];


        // add columns correspond type
        if (type === 'Booking') {
            {
                columns.push({
                    title: 'Booking No',
                    dataIndex: 'Booking No',
                    key: 'Booking No',

                },
                    {
                        title: 'Booking Date',
                        dataIndex: 'Booking Date',
                        key: 'date',
                    });
            }
        } else {
            columns.push({
                dataIndex: 'Enquiry No.',
                key: 'input',
                title: 'Enquiry No.',
            })
        }

        return (
            <Table
                loading={loading}
                width="100%"
                size="small"
                scroll={{ x: true }}
                // scroll={{ x: true, y: 750 }}
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                dataSource={data}
                columns={columns}
                pagination={false}
            ></Table>
        );
    }

    return (
        <section className="bulk-kec-change listing">
            <div className="page-header">
                <Title level={3}>Bulk KEC Change</Title>

                <div className="page-actions">

                    <div>
                        <Form
                            {...layout}
                            form={form}
                            name="new-record"
                            initialValues={{}}
                        >
                            <Row gutter={20}>

                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={'mode'}
                                        label="Mode"
                                        rules={[
                                            {
                                                message: 'Please select to mode.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select defaultValue={'Booking'} options={modeOptions} onChange={(e) => handleClick(e)} />

                                    </Form.Item>
                                </Col>
                          
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={'from_kec'}
                                        label="From KEC"
                                        rules={[
                                            {
                                                message: 'Please select from kec.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <SELECT options={kec} onChange={(e) => listing(e)} isClearable={true} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={'to_kec'}
                                        label="To KEC"
                                        rules={[
                                            {
                                                message: 'Please select to kec.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <SELECT style={{ width: '100%' }} options={kec} onChange={(e) => getToKec(e)} isClearable={true} />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Space size="middle">
                                <Popconfirm
                                    title={`Are you sure you want to change the kec of these selected ${type} records`}
                                    onConfirm={() => changeKec()}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                    disabled={selectedRowKeys.length === 0 || !toKec}
                                >

                                    <Button disabled={selectedRowKeys.length === 0 || !toKec} loading={btnloading} type="secondary" >
                                        Assign {type} to Another KEC
                                    </Button>
                                </Popconfirm>
                            </Space>
                            <div style={{ marginTop: 16 }}>
                                <Space>
                                    <span>{selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ''}</span>
                                </Space>
                            </div>
                        </Form>
                    </div>

                    <div>
                        {record.length ? (
                            <>
                                <TableView
                                    data={record}
                                    selectedRowKeys={selectedRowKeys}
                                    onSelectChange={(keys, record) => {
                                        onSelectChange(keys, record);
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    );
}
