/**
 *@author Nihala Dilshi
 * 
 */

import React, { useState, useEffect, useContext } from 'react';

import { withRouter } from 'react-router-dom';

import { StatusMasters } from '../../../../models';

import moment from 'moment';


import { Select, Radio, Form, DatePicker, Input } from 'antd';
import './follow_up_status.scss'

const { Option } = Select;

/**
 * Component for selecting follow up status
 *
 * @param {*} param0
 * @returns
 */
function FollowUpStatus({ callback, content, form }) {

    const [status, setStatus] = useState([]);
    const [selectedOption, setSelectedOption] = useState(content.enquiry_status || '');
    const [description, setDescription] = useState([]);
    const [followUpStatus, setFollowUpStatus] = useState();
    const [range, setRange] = useState();
    const { TextArea } = Input;



    useEffect(() => {
        getStatus();
    }, []);

    useEffect(async () => {
        const result = await getDescription()
        setDescription(result)
    }, [selectedOption]);

    /**
     *
     *   Load the follow up status
     */
    async function getDescription() {
        if (selectedOption) {
            let queries = [
                {
                    field: 'category',
                    value: selectedOption,
                },
                {
                    field: 'mode',
                    value: 'follow_up_status',
                },
            ];

            return StatusMasters.get(queries).then((res) => {
                let a = res.status_masters || []
                return (a)
            })
        }
    }

    function disabledDate(current) {
        // Disable dates from the next year onwards
        const nextYearStart = moment().add(2, 'year').startOf('year');
        return current && current >= nextYearStart;

    }
    
    function updateTime(dt) {
        setRange(dt);

        if (followUpStatus) {
            callback(followUpStatus, selectedOption, dt);
        }
    }

    async function getStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'enquiry_status',
            },
            {
                field: 'active',
                value: 'true',
            }
        ];

        const result = await StatusMasters.get(queries);
        result.status_masters.sort((a, b) => {
            // Ensure "serial_number" is treated as a number for sorting
            return parseInt(a["serial_number"]) - parseInt(b["serial_number"]);
        });

        setStatus(result.status_masters || []);
    }

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
        form.setFieldsValue({ follow_up_status: null });
    };

    /**
     * On change of the select box ,
     * append object along with id
     */
    function handleChange(id) {
        setFollowUpStatus(id)
        if (selectedOption !== 'LIVE') {
            callback(id, selectedOption);
        } else {
            if (range) {
                callback(id, selectedOption, range);

            }
        }
    }


    return (
        <div className="stock-pointer">
            <Radio.Group onChange={handleRadioChange} value={selectedOption}>
                {status.map((option, key) => (
                    <Radio key={key} value={option.name}>
                        {option.name}
                    </Radio>
                ))}
            </Radio.Group>

            {selectedOption && description ? (

                <>
                    <Form.Item
                        label="Description"
                        name="follow_up_status"
                        rules={[{ required: true, message: 'Please select a Follow up status!' }]}
                    >
                        <Select style={{ width: 300 }} onChange={handleChange}>
                            {description.map((option, key) => (
                                <Option key={key} value={option.description}>
                                    {option.description}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </>
            ) : null}

            {selectedOption === 'LIVE' ? (
                <Form.Item
                    label="Next follow up Date"
                    name={"nextDate"}
                    rules={[{ required: true, message: 'Please select Date!' }]}
                >

                    <DatePicker
                        onChange={(time) => {
                            updateTime(time);
                        }}
                        inputReadOnly
                        disabledDate={disabledDate}
                        format={"DD/MM/YYYY"}
                    />
                </Form.Item>
            ) : null}

            <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                    {
                        required: true,
                        message: 'Please enter remark',
                    },
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>
        </div>
    );
};


export default withRouter(FollowUpStatus);
