/**
 * Practice documenting the file as you write
 */

// Load the Native Libraries

import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
// Load theme Components
import {
    Badge,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Menu,
    message,
    Progress,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tabs,
    Tag,
    Typography,
} from 'antd';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
// Load the Dependent Libs
import { Link } from 'react-router-dom';
import Rupee from '../../../../components/rupee/rupee';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';
import { GlobalContext } from '../../../../Store';
import FirebaseUtils from '../../../../utils/firebase.utils';
import { ExportXLS } from './ExportXLS';
import './finance-tracker.scss';
import { Bills, Departments, Bookings, Requests } from '../../../../models';

import { Cart } from '../../../../models';
import { cityKeys ,dealerCodeKey} from '../../../global-config';
import ApiUtils from '../../../../utils/api.utils';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

let filters = {
    financeTypes: [
        { text: 'In House', value: 'In House' },
        { text: 'Own Fund', value: 'Own Fund' },
        { text: 'Direct', value: 'Direct' },
    ],

    billingTypes: [
        { text: 'Requested', value: 'requsted' },
        { text: 'Approved', value: 'approved' },
        { text: 'Invoiced', value: 'Invoiced' },
    ],

    accessories: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Ready for Fitment', value: 'Ready for Fitment' },
        { text: 'Accessories Fitted', value: 'Accessories Fitted' },
        { text: 'Ready for Billing', value: 'Ready for Billing' },
    ],
    finance: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Documents Collected', value: 'Documents Collected' },
        { text: 'Finance Approval Pending', value: 'Finance Approval Pending' },
        { text: 'Delivery Order Pending', value: 'Delivery Order Pending' },
        { text: 'Disbursement Pending', value: 'Disbursement Pending' },
    ],
    retail: [
        { text: 'pending', value: 'pending' },
        { text: 'requested', value: 'requested' },
        { text: 'approved', value: 'approved' },
        { text: 'Invoiced', value: 'Invoiced' },
    ],
    insurance: [
        { text: 'Ready for Issue', value: 'Ready for Issue' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Payment Done', value: 'Payment Done' },
        { text: 'Insurance Issued', value: 'Insurance Issued' },
    ],
    rto: [
        { text: 'pending', value: 'pending' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Waiting for TP', value: 'Waiting for TP' },
        { text: 'Road Tax Issued', value: 'Road Tax Issued' },
    ],
    model: [
        { text: 'SONET', value: 'SONET' },
        { text: 'SELTOS', value: 'SELTOS' },
        { text: 'CARNIVAL', value: 'CARNIVAL' },
        { text: 'EV6', value: 'EV6' },
    ],
    delivery: [
        { text: 'requested', value: 'requested' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Delivery Note Taken', value: 'Delivery Note Taken' },
        { text: 'Delivery Completed', value: 'Delivery Completed' },
    ],
};

var cityCode = cityKeys;

/**
 * Finance Tracker is Meant to give a glimpse of the finance status at any moment
 *
 */

export default function PenetrationTracker() {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        getDepartments();
    }, []);
    // Use Object.keys to iterate over the keys and create the desired array of objects
    const dealerCodeArray = Object.keys(dealerCodeKey).map((code) => {
        return { text: code, value: code };
    });
    // set dealer codes to filter array
    filters['dealerCode'] = dealerCodeArray;
    /**
     * Get all the departments
     */
    async function getDepartments() {
        var queries = [
            {
                field: 'description',
                value: 'Department Handling Sales',
            },
        ];

        const { departments = [] } = await Departments.get(queries);

        setDepartments(departments);
    }

    const allocatedColumns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.id ? (
                            <Link
                                to={`/requests/${record.id}`}
                                style={{
                                    fontSize: '12px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {console.log('red', record)}
                                {record.customer.customerName}
                                <br></br>
                                {record.customer.phone}
                            </Link>
                        ) : (
                            <div>
                                <div>{record['Cust Name']}</div>
                                <div>{record['phone']}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 200,
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '12px' }}>{record.allocation.vinNo}</div>
                        <div style={{ fontSize: '10px' }}>{record.variant}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.color}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.destination_city ? record.destination_city : record.city}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'KEC',
            render: (text, record) => {
                return <div>{record.kec} </div>;
            },
            width: 150,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            filters: filters.model,
            onFilter: (value, record) => record.model.indexOf(value) === 0,
            width: 150,
        },
        {
            title: 'Allocated DealerCode',
            render: (text, record) => {
                return <div>{record.dealerCode} </div>;
            },

            filters: filters.dealerCode,
            onFilter: (value, record) => record.dealerCode.indexOf(value) === 0,
            width: 150,
        },
        {
            title: 'Allocation Date',
            // dataIndex: 'billing.status',
            render: (text, record) => {
                return record.allocation.created_at;
            },
        },
        {
            title: 'Booking Date',

            // dataIndex: 'booking date',
            render: (record) => {
                return record.bookingDate;
            },
        },

        {
            title: 'Expected Retail Date',
            // dataIndex: 'billing.status',
            render: (text, record) => {
                return moment(record.finance.expected_retail_date).format('DD/MM/YYYY');
            },
            width: 150,
        },

        {
            title: 'Sanctioned Amount',
            render: (text, record) => {
                return <div>{record.finance.sanctioned_amount} </div>;
            },
            width: 150,
        },

        {
            title: 'Type',
            dataIndex: 'finance',
            render: (text, record) => {
                let tagColor = 'gold';
                if (record.finance) {
                    if (record.finance.type == 'In House') {
                        tagColor = 'green';
                    } else if (record.finance.type == 'Own Fund') {
                        tagColor = 'blue';
                    } else if (record.finance.type == 'Direct') {
                        tagColor = 'red';
                    } else {
                        tagColor = 'gold';
                    }
                } else {
                    tagColor = 'gold';
                }
                return record.finance && <Tag color={tagColor}>{record.finance.type}</Tag>;
            },
            filters: filters.financeTypes,
            onFilter: (value, record) => {
                return record.finance && record.finance.type.indexOf(value) === 0;
            },
            width: 150,
        },

        {
            title: 'Bank',
            dataIndex: 'bankName',
            render: (text, record) => {
                return (
                    <div
                        style={{
                            fontSize: '12px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {record && record.finance && record.finance.bank}
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Payable',
            dataIndex: 'vehicleCost',
            render: (text, record) => {
                var vc = record.cartTotal;
                // var vcr = vc.toFixed(2);
                return <div>{vc}</div>;
            },
            width: 150,
        },
        {
            title: 'Paid',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.receiptTotal;
                // let dpr = dp.toFixed(2);
                return <div>{dp} </div>;
            },
            width: 150,
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            render: (text, record) => {
                let balance = record.cartTotal - record.receiptTotal;
                // let balanceR = balance.toFixed(2);
                return <div>{balance.toFixed(2)}</div>;
            },
            width: 150,
        },

        {
            title: 'Pending for Clearing',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.pending;

                let dpr = parseFloat(dp).toFixed(2);

                return <div>{dpr}</div>;
            },
            width: 200,
        },

        {
            title: 'Finance',
            render: (text, record) => {
                let progressPercent;
                if (record && record.finance) {
                    if (record.finance.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.finance.status == 'Data Collected') {
                        progressPercent = 17;
                    } else if (record.finance.status == 'Documents Collected') {
                        progressPercent = 34;
                    } else if (record.finance.status == 'Finance Approval Pending') {
                        progressPercent = 50;
                    } else if (record.finance.status == 'Delivery Order Pending') {
                        progressPercent = 67;
                    } else if (record.finance.status == 'Disbursement Pending') {
                        progressPercent = 84;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.finance && record.finance.status}
                        </div>
                        <Progress percent={progressPercent} steps={6} showInfo={progressPercent == 0 ? false : true} />
                    </div>
                );
            },
            filters: filters.finance,
            onFilter: (value, record) => record && record.finance && record.finance.status.indexOf(value) === 0,
            width: 150,
        },

        {
            title: 'Accessories',
            render: (text, record) => {
                let progressPercent;
                if (record && record.accessories) {
                    if (record.accessories.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.accessories.status == 'Data Collected') {
                        progressPercent = 20;
                    } else if (record.accessories.status == 'Ready for Fitment') {
                        progressPercent = 40;
                    } else if (record.accessories.status == 'Accessories Fitted') {
                        progressPercent = 60;
                    } else if (record.accessories.status == 'Ready for Billing') {
                        progressPercent = 80;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.accessories && record.accessories.status}
                        </div>
                        <Progress percent={progressPercent} steps={5} showInfo={progressPercent == 0 ? false : true} />
                    </div>
                );
            },
            filters: filters.accessories,
            onFilter: (value, record) => record && record.accessories && record.accessories.status.indexOf(value) === 0,
            width: 200,
        },
        {
            title: 'ERD',
            render: (text, record) => {
                let erd = null;

                if (record.finance && record.finance.expected_retail_date) {
                    // erd = record.finance.expected_retail_date;

                    erd = moment(record.finance.expected_retail_date);

                    // erd = moment('10/10/2020')
                } else {
                    // erd = null
                    // erd = moment('10/10/2020')
                }

                return (
                    <DatePicker
                        format={dateFormat}
                        value={erd}
                        onChange={(dt) => {
                            updateERD(dt, record);
                        }}
                    />
                );
            },
            width: 300,
        },

        // {
        //     title: 'Remarks',
        //     dataIndex: 'remarks',
        //     render: (text, record) => {
        //         return (
        //             <div
        //                 style={{
        //                     fontSize: '10px',
        //                     textTransform: 'capitalize'
        //                 }}
        //             >
        //                 {record && record.finance && record.finance.remarks}
        //             </div>
        //         );
        //     },
        //     width: 150
        // }
    ];

    const teamReportColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            // render: (value, record, index) => {
            //     return (
            //         <div size="middle">
            //             {record.isteamLeader ? (
            //                 <span>
            //                     <strong> {index + 1}</strong>
            //                 </span>
            //             ) : (
            //                 <div>
            //                     <div>{index + 1}</div>
            //                 </div>
            //             )}
            //         </div>
            //     );
            // }
        },
        {
            title: 'KEC',
            dataIndex: 'kec',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.name}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.name}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 350,
        },

        {
            title: 'RETAILS',
            dataIndex: 'retails',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.retails}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.retails}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },
        {
            title: 'CASH',
            dataIndex: 'CASH',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.cash}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.cash}</div>
                            </div>
                        )}
                    </Space>
                );
            },

            width: 100,
        },

        {
            title: 'FINANCE',
            dataIndex: 'finance',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.finance}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.finance}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },

        {
            title: 'DIRECT',
            dataIndex: 'direct',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.direct}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.direct}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },
        {
            title: 'IN HOUSE',
            dataIndex: 'inhouse',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.isteamLeader ? (
                            <span
                                style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong> {record.inhouse}</strong>
                            </span>
                        ) : (
                            <div>
                                <div>{record.inhouse}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 100,
        },
        {
            title: '% OF ACH',
            dataIndex: 'ach',
            render: (text, record) => {
                var color = 'red';

                if (record.ACH >= 90) {
                    color = '#52c41a';
                } else if (record.ACH >= 50) {
                    color = '#ffb077';
                } else if (record.ACH >= 40) {
                    color = '#faad14';
                } else if (record.ACH >= 25) {
                    color = '#feec82';
                } else if (record.ACH > 0) {
                    color = '#ff4d4f';
                } else if (record.ACH === 0) {
                    color = 'white';
                } else {
                }

                return (
                    <div style={{ backgroundColor: color, padding: '0px 8px' }}>
                        <Space size="middle">
                            <span>{record.ACH}</span>
                        </Space>
                    </div>
                );
            },
            width: 100,
        },
    ];

    function updateERD(dt, record) {
        FirebaseUtils.updateERD(record.id, dt, record).then(() => {
            message.success('Updated ERD');

            record.refresh();

            // getRequests(location, range);

            // setRange(range);
        });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.id ? (
                            <Link
                                style={{
                                    fontSize: '12px',
                                    textTransform: 'capitalize',
                                }}
                                to={`/requests/${record.id}`}
                            >
                                {record.customer.customerName}
                                <br></br>
                                {record.customer.phone}
                            </Link>
                        ) : (
                            <div>
                                <div>{record['Cust Name']}</div>
                                <div>{record['phone']}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 200,
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '12px' }}>{record.allocation.vinNo}</div>
                        <div style={{ fontSize: '10px' }}>{record.variant}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.color}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record.destination_city ? record.destination_city : record.city}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'KEC',
            // dataIndex: 'billing.status',
            render: (text, record) => {
                return record.kec;
            },
        },

        {
            title: 'Type',
            dataIndex: 'finance',
            render: (text, record) => {
                let tagColor = 'gold';
                if (record.finance) {
                    if (record.finance.type == 'In House') {
                        tagColor = 'green';
                    } else if (record.finance.type == 'Own Fund') {
                        tagColor = 'blue';
                    } else if (record.finance.type == 'Direct') {
                        tagColor = 'red';
                    } else {
                        tagColor = 'gold';
                    }
                } else {
                    tagColor = 'gold';
                }
                return record.finance && <Tag color={tagColor}>{record.finance.type}</Tag>;
            },
            filters: filters.financeTypes,
            onFilter: (value, record) => {
                return record.finance && record.finance.type.indexOf(value) === 0;
            },
            width: 150,
        },

        {
            title: 'Allocated DealerCode',
            render: (text, record) => {
                return record.dealerCode;
            },
        },

        {
            title: 'Retail Status',
            dataIndex: 'billing.status',
            render: (text, record) => {
                let tagColor = 'gold';

                switch (record.billing.status) {
                    case 'requested':
                        tagColor = 'orange';
                        break;
                    case 'approved':
                        tagColor = 'red';
                        break;

                    case 'Invoiced':
                        tagColor = 'blue';
                        break;

                    default:
                }

                return <Tag color={tagColor}>{record.billing.status}</Tag>;
            },
            filters: filters.billingTypes,
            onFilter: (value, record) => {
                return record.billing.status.indexOf(value) === 0;
            },
            width: 150,
        },

        {
            title: 'Allocation Date',
            // dataIndex: 'billing.status',
            render: (text, record) => {
                return record.allocation.allocated_at;
            },
        },

        {
            title: 'Booking Date',
            // dataIndex: 'booking date',
            render: (text, record) => {
                return record.bookingDate;
            },
        },

        {
            title: 'Retail Date',
            // dataIndex: 'billing.status',
            render: (text, record) => {
                return record.billing.invoiced_at;
            },
        },

        {
            title: 'Bank',
            dataIndex: 'bankName',
            render: (text, record) => {
                return (
                    <div
                        style={{
                            fontSize: '12px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {record && record.finance && record.finance.bank}
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Payable',
            dataIndex: 'vehicleCost',
            render: (text, record) => {
                var vc = record.cartTotal;
                // var vcr = vc.toFixed(2);
                return <div>{vc}</div>;
            },
            width: 150,
        },
        {
            title: 'Paid',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.receiptTotal;
                // let dpr = dp.toFixed(2);

                return (
                    <div>
                        <Badge status={record.accounts && record.accounts.ac_code && record.accounts.ac_code.value ? 'success' : 'error'} text={dp} />
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            render: (text, record) => {
                let balance = record.cartTotal - record.receiptTotal;
                let balanceR = balance.toFixed(2);
                return <div>{balanceR}</div>;
            },
            width: 150,
        },
        {
            title: 'Pending for Clearing',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.pending;

                let dpr = parseFloat(dp).toFixed(2);

                return <div>{dpr}</div>;
            },
            width: 200,
        },
        // {
        //     title: 'Finance',
        //     render: (text, record) => {
        //         let progressPercent;
        //         if (record && record.finance) {
        //             if (record.finance.status == 'pending') {
        //                 progressPercent = 0;
        //             } else if (record.finance.status == 'Data Collected') {
        //                 progressPercent = 17;
        //             } else if (record.finance.status == 'Documents Collected') {
        //                 progressPercent = 34;
        //             } else if (
        //                 record.finance.status == 'Finance Approval Pending'
        //             ) {
        //                 progressPercent = 50;
        //             } else if (
        //                 record.finance.status == 'Delivery Order Pending'
        //             ) {
        //                 progressPercent = 67;
        //             } else if (
        //                 record.finance.status == 'Disbursement Pending'
        //             ) {
        //                 progressPercent = 84;
        //             } else {
        //                 progressPercent = 100;
        //             }
        //         } else {
        //             progressPercent = 0;
        //         }
        //         return (
        //             <div>
        //                 <div
        //                     style={{
        //                         fontSize: '10px',
        //                         textTransform: 'capitalize'
        //                     }}
        //                 >
        //                     {record && record.finance && record.finance.status}
        //                 </div>
        //                 <Progress
        //                     percent={progressPercent}
        //                     steps={6}
        //                     showInfo={progressPercent == 0 ? false : true}
        //                 />
        //             </div>
        //         );
        //     },
        //     filters: filters.finance,
        //     onFilter: (value, record) =>
        //         record &&
        //         record.finance &&
        //         record.finance.status.indexOf(value) === 0,
        //     width: 150
        // },
        // {
        //     title: 'Retail',
        //     render: (text, record) => {
        //         let tagColor = 'gold';
        //         if (record && record.billing) {
        //             if (record.billing.status == 'pending') {
        //                 tagColor = 'gold';
        //             } else if (record.billing.status == 'requested') {
        //                 tagColor = 'red';
        //             } else if (record.billing.status == 'approved') {
        //                 tagColor = 'blue';
        //             } else if (record.billing.status == 'Invoiced') {
        //                 tagColor = 'green';
        //             }
        //         }
        //         return (
        //             record &&
        //             record.billing && (
        //                 <Tag color={tagColor}>{record.billing.status}</Tag>
        //             )
        //         );
        //     },
        //     filters: filters.retail,
        //     onFilter: (value, record) =>
        //         record &&
        //         record.billing &&
        //         record.billing.status.indexOf(value) === 0
        // },
        {
            title: 'Accessories',
            render: (text, record) => {
                let progressPercent;
                if (record && record.accessories) {
                    if (record.accessories.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.accessories.status == 'Data Collected') {
                        progressPercent = 20;
                    } else if (record.accessories.status == 'Ready for Fitment') {
                        progressPercent = 40;
                    } else if (record.accessories.status == 'Accessories Fitted') {
                        progressPercent = 60;
                    } else if (record.accessories.status == 'Ready for Billing') {
                        progressPercent = 80;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.accessories && record.accessories.status}
                        </div>
                        <Progress percent={progressPercent} steps={5} showInfo={progressPercent == 0 ? false : true} />
                    </div>
                );
            },
            filters: filters.accessories,
            onFilter: (value, record) => record && record.accessories && record.accessories.status.indexOf(value) === 0,
            width: 200,
        },
        {
            title: 'Insurance',
            render: (text, record) => {
                let progressPercent;
                if (record && record.insurance) {
                    if (record.insurance.status == 'Ready for Issue') {
                        progressPercent = 0;
                    } else if (record.insurance.status == 'Payment Pending') {
                        progressPercent = 34;
                    } else if (record.insurance.status == 'Payment Done') {
                        progressPercent = 67;
                    } else if (record.insurance.status == 'Insurance Issued') {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div className="insurance-block">
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.insurance && record.insurance.status}
                        </div>
                        <Progress percent={progressPercent} steps={3} showInfo={progressPercent == 0 ? false : true} />
                        {record && record.fasttag && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                fast tag
                            </Tag>
                        )}
                    </div>
                );
            },
            filters: filters.insurance,
            onFilter: (value, record) => record && record.insurance && record.insurance.status.indexOf(value) === 0,
            width: 150,
        },
        {
            title: 'RTO',
            render: (text, record) => {
                let progressPercent;
                if (record && record.rto) {
                    if (record.rto.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.rto.status == 'Payment Pending') {
                        progressPercent = 34;
                    } else if (record.rto.status == 'Waiting for TP') {
                        progressPercent = 67;
                    } else if (record.rto.status == 'Road Tax Issued') {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.rto && record.rto.status}
                        </div>
                        <Progress percent={progressPercent} steps={3} showInfo={progressPercent == 0 ? false : true} />
                    </div>
                );
            },
            filters: filters.rto,
            onFilter: (value, record) => record && record.rto && record.rto.status.indexOf(value) === 0,
            width: 150,
        },
        {
            title: 'Delivery',
            render: (text, record) => {
                let progressPercent;
                if (record && record.delivery) {
                    if (record.delivery.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.delivery.status == 'requested') {
                        progressPercent = 25;
                    } else if (record.delivery.status == 'Approved') {
                        progressPercent = 50;
                    } else if (record.delivery.status == 'Delivery Note Taken' || (record.delivery.delivery_note_status && record.delivery.delivery_note_status === 'completed')) {
                        progressPercent = 75;
                    } else if (record.delivery.status == 'Delivery Completed') {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {record && record.delivery && record.delivery.status}
                        </div>
                        <Progress percent={progressPercent} steps={3} showInfo={progressPercent == 0 ? false : true} />
                        {record && record.delivery && record.delivery.schedule === 'Delivery Scheduled' ? (
                            <div>
                                <div
                                    style={{
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Scheduled_At :
                                </div>
                                {/* <div
                                    style={{ fontSize: '10px', color: 'grey' }}
                                    >
                                    {record &&
                                        record.delivery &&
                                        record.delivery.scheduled_delivery_date}
                                    </div> */}
                                <div style={{ fontSize: '10px', color: 'grey' }}>
                                    {record && record.delivery && moment(record.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A')}
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            },
            filters: filters.delivery,
            onFilter: (value, record) => record && record.delivery && record.delivery.status.indexOf(value) === 0,
            sorter: (a, b) => {
                if (a && a.request && a.request.delivery && b && b.request && b.request.delivery) {
                    return a.request.delivery.status.length - b.request.delivery.status.length;
                } else if (a && a.request && a.request.delivery) {
                    return a.request.delivery.status.length - 0;
                } else if (b && b.request && b.request.delivery) {
                    return 0 - b.request.delivery.status.length;
                } else {
                    return 0 - 0;
                }
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend'],
            width: 150,
        },

        // {
        //     title: 'Remarks',
        //     dataIndex: 'remarks',
        //     render: (text, record) => {
        //         return (
        //             <div
        //                 style={{
        //                     fontSize: '10px',
        //                     textTransform: 'capitalize'
        //                 }}
        //             >
        //                 {record && record.finance && record.finance.remarks}
        //             </div>
        //         );
        //     },
        //     width: 150
        // }
    ];

    var allocatedQueries = (range) => {
        return [
            {
                field: 'actual_billing.status',
                operator: '==',
                value: 'pending',
            },
            {
                field: 'allocation.status',
                operator: 'in',
                value: ['Allocated', 'requested', 'approved'],
            },
        ];
    };

    // const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('day'));

    var billingQueries = (range) => {
        return [
            {
                field: 'billing.invoiced_date',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'billing.invoiced_date',
                operator: '<=',
                value: moment(range).valueOf(),
            },
        ];
    };

    var deliveryPending = () => {
        return [
            {
                field: 'actual_delivery.status',
                operator: 'in',
                value: ['pending', 'requested', 'approved', 'Settlement Pending', 'Settlement Ready'],
                // value: 'pending',
            },

            // {
            //     field: 'billing.status',
            //     operator: 'in',
            //     value: ['Invoiced', 'requested', 'approved'],
            // },
            // {
            //     field: 'delivery.status',
            //     operator: 'in',
            //     value: ['pending', 'requested', 'approved', 'Delivery Note Taken'],
            //     // value: 'pending',
            // },
            {
                field: 'billing.status',
                operator: '!=',
                value: 'cancelled',
            },
        ];
    };

    // function updateTime(dt) {
    //     setRange(dt);
    // }

    return (
        <>
            <div>
                <div className="finance-tracker listing">
                    <div className="page-header">
                        <div className="left">
                            <Title level={3}>Finance Tracker</Title>
                        </div>

                        <div className="right">
                            {/* <div className="page-actions">
                                <DatePicker
                                    inputReadOnly
                                    format={dateFormat}
                                    value={range}
                                    onChange={updateTime}
                                    allowClear={false}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>

                <Tabs defaultActiveKey="0" type="card" size={'small'}>
                    <TabPane tab="Allocation" key="0">
                        <Allocation
                            departments={departments}
                            // range={range}
                            queries={allocatedQueries}
                            columns={allocatedColumns}
                            isDropdown={true}
                        />
                    </TabPane>

                    <TabPane tab="Retail (Month Wise)" key="1">
                        <Allocation
                            departments={departments}
                            // range={range}
                            queries={billingQueries}
                            columns={columns}
                            isBilling={true}
                        />
                    </TabPane>

                    <TabPane tab="Retails (Delivery Pending)" key="2">
                        <Allocation
                            departments={departments}
                            // range={range}
                            queries={deliveryPending}
                            columns={columns}
                            isBilling={true}
                            hideDate={true}
                        />
                    </TabPane>

                    <TabPane tab="Team Report" key="3">
                        <TeamView columns={teamReportColumns} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}

/**
 * Component shows the finance penentration team wise
 *
 * @param {*} param0
 * @returns
 */
function TeamView({ columns }) {
    const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('day'));

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [finResult, setFinResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    function updateTime(dt) {
        setRange(dt);
        getRequests(location, moment(dt));
    }

    useEffect(() => {
        getRequests(location, range);
    }, []);

    function getRequests(city) {
        setLoading(true);

        var end_time = new Date(range);

        var start_time = new Date(end_time.getFullYear(), end_time.getMonth(), 1);

        var params = {
            city: city,
            start_time: start_time.valueOf(),
            end_time: end_time.valueOf(),
        };

        FirebaseUtils.getFinancePenetration(params).then((result) => {
            setLoading(false);

            // console.log(result);

            setFinResult(result.data);
        });
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);
    }

    return (
        <div className="finance-tracker listing">
            <div></div>

            <div className="header">
                <Button
                    onClick={() => {
                        setRange(moment.tz('Asia/Calcutta').endOf('day'));
                        getRequests(location);
                    }}
                    type="secondary"
                    size={'small'}
                >
                    Refresh
                </Button>

                <Dropdown overlay={menu}>
                    <Button size={'small'}>
                        {location} <DownOutlined />
                    </Button>
                </Dropdown>

                <DatePicker inputReadOnly format={dateFormat} value={range} onChange={updateTime} allowClear={false} />

                {/*table data export to csc component*/}
                {finResult && <ExportXLS csvData={finResult} />}
            </div>

            <div>
                {loading ? (
                    <>
                        <PlaceHolder type="listing" />
                    </>
                ) : (
                    <Table
                        size="small"
                        rowKey={(record) => record.index}
                        dataSource={finResult}
                        columns={columns}
                        pagination={false}
                        summary={(pageData) => {
                            let cashTotal = 0,
                                directTotal = 0,
                                financeTotal = 0,
                                inhouseTotal = 0,
                                retailsTotal = 0;

                            pageData.forEach((entry) => {
                                if (entry.isteamLeader) {
                                    cashTotal += entry.cash;
                                    directTotal += entry.direct;
                                    financeTotal += entry.finance;
                                    inhouseTotal += entry.inhouse;
                                    retailsTotal += entry.retails;
                                }
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>Total</Table.Summary.Cell>

                                        <Table.Summary.Cell>
                                            <Text>
                                                <strong>{retailsTotal}</strong>
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text>
                                                <strong>{cashTotal}</strong>
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text>
                                                <strong>{financeTotal}</strong>
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text>
                                                <strong>{directTotal}</strong>
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Text>
                                                <strong>{inhouseTotal}</strong>
                                            </Text>
                                        </Table.Summary.Cell>

                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
}

/**
 * Component to display requests that are in allocation
 *
 * @param {*} param0
 * @returns
 */
function Allocation({ queries, columns, isBilling, hideDate, isDropdown, departments }) {
    const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('month'));

    const { user, disableDisplayPhoneNumber } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState({ requests: [] });

    const [finResult, setFinResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    const [option, setOption] = useState();

    const [count, setCount] = useState([]);

    const [approval, setApproval] = useState(false);

    const [billType, setBillType] = useState('Surge Billing');

    const [dms, setDms] = useState(false);

    function updateTime(dt) {
        setRange(dt);
        getRequests(location, moment(dt));
    }

    useEffect(() => {
        if (location && range) {
            if (isBilling) {
                getRequests(location, range);
            } else {
                getRequests(location);
            }
        }
    }, []);

    useEffect(() => {
        getRequests(location);
    }, [billType]);

    function refresh() {
        getRequests(location);
    }

    /**
     *
     *
     * @param {*} location
     * @param {*} initialQueries
     * @param {*} isBilling
     * @returns
     */
    function getAllRequestsData(location, initialQueries, isBilling) {
        let arr = [];

        return new Promise((resolve, reject) => {
            // For TL's  only their records should be listed
            if (user.role === 'tl') {
                initialQueries.push({ field: 'teamLeader', value: user.name });
            }

            // Ensure locations is an array
            if (!Array.isArray(location)) {
                location = [location];
            }

            // To iterate each location to get data  
            let getLocations = location.map((location) => {
                return FirebaseUtils.getRequests(location, initialQueries).then((result) => {
                            // After getting all the request data ,
                            // We have to make an array with all the data that needs to be shown on the table
                            // there would be many ways to approach this , lets try one .  
                    if (isBilling) {
                        return result.requests
                            .filter((item) => item.billing.status !== 'Cancelled' && item.status !== 'removed')
                            .map((request) => ({
                                ...request,
                                refresh: refresh,
                            }));
                        } else {
                            return result.requests
                            .filter((item) => item.status !== 'removed')
                            .map((request) => ({
                                ...request,
                                refresh: refresh,
                            }));
                        }
                    });
        });
        Promise.all(getLocations)
        .then((results) => {
            // By selecting multiple locations we need to set locations into a single array
            const result = results.flat();
            console.log(result);
            resolve(result);
        })
        .catch((error) => reject(error));  
    });
    }

    /**
     * Get stock Data
     */
    function getRequests(location, range) {
        let department = departments.filter((department) => department.location === location)[0];

        setLoading(true);

        let initialQueries = [];

        var dmsQueries = (range) => {
            return [
                {
                    field: 'billing.status',
                    operator: '==',
                    value: 'pending',
                },
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['Allocated', 'requested', 'approved'],
                },
            ];
        };

        if (range && !isBilling && !hideDate) {
            initialQueries = queries(range);
            // field name should change parallel to select option

            let dateQueries = [
                {
                    field: option,
                    operator: '>=',
                    value: moment(range).startOf('month').valueOf(),
                },
                {
                    field: option,
                    operator: '<=',
                    value: moment(range).endOf('day').valueOf(),
                },
            ];
            dateQueries.map((item) => {
                initialQueries.push(item);
            });
        } else {
            if (dms) {
                initialQueries = dmsQueries(range);
            } else {
                initialQueries = queries(range);
            }
        }

        // As a first stage , lets load all the allocated vehicles that are to be delivered . These are the cases for which we have to collect funds
        // At our best attempt we have to reduce the time for delivery for these cases

        // We neednt get the data for vehicle overview , It includes unallocated vehicles too ,
        // Instead we should load all the ongoing requests
        getAllRequestsData(location, initialQueries, isBilling).then((result) => {
            //To get Booking date from bookings table using bookingNo
            return Promise.all(
                result.map((res) => {
                    return Bookings.getRecord(res.bookingNo).then((booking) => {
                        // setBookingDate(booking['Booking Date'])
                        return {
                            ...res,
                            bookingDate: booking['Booking Date'],
                        };
                    });
                })
            ).then((result) => {
                console.log(result);
                // Get all accounts with head
                var accounts = result
                    .filter((account) => account.accounts && account.accounts.ac_code)
                    .map((account) => account.accounts.ac_code.value);
                if (isBilling && accounts.length) {
                    ApiUtils.getBulkLedgerDetails(department, accounts).then((response) => {
                        getTotal(response);
                    });
                } else {
                    getTotal();
                }

                function getTotal(response) {
                    return Promise.all(
                        result.map((request) => {
                            return getTotalValues(request, response);
                        })
                    ).then((result) => {
                        // Penetration count variables

                        let inHouse = 0;
                        let ownFund = 0;
                        let direct = 0;
                        let inHouseP = 0;
                        let ownFundP = 0;
                        let directP = 0;
                        let total = 0;
                        let counts = [];
                        //console.log('Final Result', result);

                        // Penetration Calculation

                        result.forEach((item) => {
                            if (item && item.finance) {
                                if (item.finance.type == 'In House') {
                                    inHouse++;
                                } else if (item.finance.type == 'Own Fund') {
                                    ownFund++;
                                } else if (item.finance.type == 'Direct') {
                                    direct++;
                                }
                                total++;
                            }
                            // disable PhoneNumber for kec and tl
                            if (disableDisplayPhoneNumber) {
                                if (item.customer && item.customer.phone) {
                                    return { ...item.customer.phone = null }
                                }
                            }

                        });


                        // Penetratiion percentage calculation

                        inHouseP = (inHouse / total) * 100;
                        inHouseP = inHouseP.toFixed(2);
                        ownFundP = (ownFund / total) * 100;
                        ownFundP = ownFundP.toFixed(2);
                        directP = (direct / total) * 100;
                        directP = directP.toFixed(2);
                        counts = {
                            InHouse: inHouse,
                            OwnFund: ownFund,
                            Direct: direct,
                            Total: total,
                            InHouseP: inHouseP,
                            OwnFundP: ownFundP,
                            DirectP: directP,
                        };

                        setResults({ requests: result });

                        setCount(counts);

                        let exportData =
                            result &&
                            result
                                .slice(0)
                                // .reverse()
                                .map((item) => {
                                    if (item && item.allocation) {
                                        if (isBilling) {
                                            // Build a array to export data to csv file
                                            let content = {
                                                // refresh: refresh,

                                                Vehicle: item.allocation.vinNo,
                                                Model: item.model,
                                                Variant: item.variant,
                                                Color: item.color,
                                                Customer: item.id ? item.customer.customerName : item['Cust Name'],
                                                Phone: item.customer && item.customer.phone,

                                                'Allocation date': item.allocation.created_at,

                                                'Booking date': item.bookingDate,

                                                'Retail date': item.actual_billing.invoiced_at,

                                                'Allocated DealerCode': item.dealerCode,

                                                KEC: item.kec,

                                                Type: item.finance.type,

                                                Payable: item.cartTotal,

                                                Paid: item.receiptTotal,

                                                Balance: item.balance,

                                                Finance: item.finance && item.finance.status,
                                                Retail: item.billing && item.billing.status,
                                                Accessories: item.accessories && item.accessories.status,
                                                Insurance: item.insurance && item.insurance.status,
                                                RTO: item.rto && item.rto.status,

                                                ERD: moment(item.finance.expected_retail_date).format('DD/MM/YY,HH:mm A'),
                                            };

                                            if (item.delivery && item.delivery.status) {
                                                if (item.delivery.schedule === 'Delivery Scheduled') {
                                                    content = {
                                                        ...content,
                                                        Delivery:
                                                            'Scheduled_At :' +
                                                            moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A'),
                                                    };
                                                } else {
                                                    content = {
                                                        ...content,
                                                        Delivery: item.delivery.status,
                                                    };
                                                }
                                            } else {
                                                content = {
                                                    ...content,

                                                    Delivery: 'Pending',
                                                };
                                            }

                                            return content;
                                        } else {
                                            // Build a array to export data to csv file
                                            let content = {
                                                // refresh: refresh,

                                                Vehicle: item.allocation.vinNo,
                                                Model: item.model,
                                                Variant: item.variant,
                                                Color: item.color,
                                                Customer: item.id ? item.customer.customerName : item['Cust Name'],
                                                Phone: item.customer && item.customer.phone,

                                                'Allocation date': item.allocation.created_at,

                                                'Retail date': item.billing.invoiced_at,

                                                'Booking date': item.bookingDate,

                                                'Allocated DealerCode': item.dealerCode,

                                                KEC: item.kec,

                                                TeamLeader: item.teamLeader,
                                                Type: item.finance && item.finance.type,
                                                Bank: item.finance && item.finance.bank,

                                                Payable: item.cartTotal,
                                                Paid: item.receiptTotal,
                                                Balance: item.balance,

                                                Finance: item.finance && item.finance.status,
                                                Accessories: item.accessories && item.accessories.status,
                                            };

                                            if (isBilling) {
                                                if (item.delivery && item.delivery.status) {
                                                    if (item.delivery.schedule === 'Delivery Scheduled') {
                                                        content = {
                                                            ...content,
                                                            Delivery:
                                                                'Scheduled_At :' +
                                                                moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A'),
                                                        };
                                                    } else {
                                                        content = {
                                                            ...content,
                                                            Delivery: item.delivery.status,
                                                        };
                                                    }
                                                } else {
                                                    content = {
                                                        ...content,
                                                        Delivery: 'Pending',
                                                    };
                                                }
                                            }

                                            return content;
                                        }
                                    }
                                });

                        setFinResult(exportData);

                        setLoading(false);
                    });
                }
            });
        });
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    var billing = ['Surge Billing', 'DMS Billing'];

    const allocationMenus = billing.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const allocationMenu = <Menu onClick={handleClick}>{allocationMenus}</Menu>;

    function handleClick(e) {
        setBillType(e.key);

        if (e.key === 'DMS Billing') {
            setDms(true);
        } else {
            setDms(false);
        }
    }

    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);
    }

    function handleChange(value) {
        setOption(value);
    }

    function handleLocations(value = []) {
        setLocation(value);
        getRequests(value);
    }

    function updateApproval(e) {
        setApproval(e.target.checked);
    }

    let filtered = result.requests;

    if (approval) {
        filtered = result.requests.filter((record) => {
            return record.billing.approvalRequired;
        });
    } else {
        filtered = result.requests;
    }

    return (
        <div className="finance-tracker listing">
            <div className="header">
                {isDropdown ? (
                    <Dropdown overlay={allocationMenu}>
                        <Button size={'small'}>
                            {billType} <DownOutlined />
                        </Button>
                    </Dropdown>
                ) : null}

                <div>
                    {/*table data export to csc component*/}

                    {finResult && <ExportXLS fileName={'Finance Tracker'} csvData={finResult} />}
                </div>

                <div className="header-actions">
                    {/* For Pending cases , we allow filter for special approval cases */}
                    {hideDate ? (
                        <>
                            <Checkbox value={approval} onChange={updateApproval}>
                                Retailed with Approval
                            </Checkbox>
                        </>
                    ) : null}

                    <Button
                        onClick={() => {
                            setRange(moment.tz('Asia/Calcutta').endOf('day'));
                            getRequests(location);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
{/* 
                    <Dropdown overlay={menu}>
                        <Button size={'small'}>
                            {location} <DownOutlined />
                        </Button>
                    </Dropdown> */}

                    
                    <Select
                        mode="multiple"
                        allowClear
                        size="small"
                        style={{ width: location.length === 0 ? "150px":"auto",marginRight:"8px"}}
                        value={location}
                        onChange={handleLocations}
                        placeholder="Select Locations"
                    >
                        {user.locations.map((location, index) => (
                            <Option key={index} value={location}>
                                {location}
                            </Option>
                        ))}
                    </Select>

                    <div className="page-actions">
                        {!isBilling ? (
                            <Select
                                size='small'
                                style={{ width:location.length === 0 ? "150px":"auto"}}
                                placeholder="Select"
                                onChange={handleChange}
                                onClear={() => {
                                    getRequests(location);
                                }}
                                allowClear
                            >
                                <Option value="allocation.created_date">Allocated Date</Option>
                            </Select>
                        ) : null}

                        {(isBilling || option) && !hideDate ? (
                            <DatePicker inputReadOnly format={dateFormat} value={range} onChange={updateTime} allowClear={false} />
                        ) : null}
                    </div>
                </div>
            </div>

            {hideDate ? (
                <></>
            ) : (
                <Row gutter={16}>
                    <Col span={5} xs={16} md={5}>
                        <div className="card">
                            <Statistic title={'Total'} value={loading ? 'Loading' : count.Total} />
                        </div>
                    </Col>

                    <Col span={5} xs={16} md={5}>
                        <div className="card">
                            <Statistic title={'In House'} value={loading ? 'Loading' : count.InHouse} />
                            <p style={{ color: 'red' }}>{count.InHouseP}%</p>
                        </div>
                    </Col>

                    <Col span={5} xs={16} md={5}>
                        <div className="card">
                            <Statistic title={'Direct'} value={loading ? 'Loading' : count.Direct} />
                            <p style={{ color: 'red' }}>{count.DirectP}%</p>
                        </div>
                    </Col>

                    <Col span={5} xs={16} md={5}>
                        <div className="card">
                            <Statistic title={'Own Fund'} value={loading ? 'Loading' : count.OwnFund} />
                            <p style={{ color: 'red' }}>{count.OwnFundP}%</p>
                        </div>
                    </Col>
                </Row>
            )}

            {loading ? <PlaceHolder type="listing" /> : <>{result && <TableView data={filtered} columns={columns} />}</>}
        </div>
    );
}

function TableView({ data, callback, columns }) {
    return (
        <Table
            // width="100%"
            size="small"
            scroll={{ x: true }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            // onChange={handleTableChange}
            pagination={false}
            summary={(pageData) => {
                let totalVC = 0;
                let totalDP = 0;
                let totalBalance = 0;
                let totalPending = 0;

                pageData.forEach((entry) => {
                    totalVC += parseFloat(entry['cartTotal']);
                    totalDP += parseFloat(entry['receiptTotal']);
                    totalBalance += parseFloat(entry['balance']);
                    totalPending += parseFloat(entry['pending']);
                });

                var allocated = false;
                columns.map((result) => {
                    if (result.title === 'ERD') allocated = true;
                });

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Title level={5}>Total</Title>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            {!allocated ? null : (
                                <>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </>
                            )}

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalVC}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalDP}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalBalance}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            {allocated ? null : (
                                <>
                                    <Table.Summary.Cell>
                                        <Text>
                                            <Rupee value={totalPending}></Rupee>
                                        </Text>
                                    </Table.Summary.Cell>
                                </>
                            )}

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        ></Table>
    );
}

/**
 * Function to get the summary of request
 */
function getTotalValues(request, response) {
    var cartTotal = loadCartTotal(request);

    var receiptTotal;

    // Checking if cs accounts data to be shown
    return Requests.disableCsAccounts(request).then(async (result) => {
        if (result.enable) {
            receiptTotal = loadReceiptTotal(request, response);
        } else {
            receiptTotal = Requests.loadReceiptTotal(request);
        }

        return Promise.all([cartTotal, receiptTotal]).then((result) => {
            // console.log(result);
            return {
                ...request,
                cartTotal: result[0],
                receiptTotal: result[1].paidtotal,
                pending: result[1].pendingcleartotal,
                balance: (result[0] - result[1].paidtotal).toFixed(2),
            };
        });
    });
}

/**
 * Loading cart total
 *
 * @param {*} request
 * @returns
 */
function loadCartTotal(request) {
    // var tableData = {};
    var arr = [];

    // For each request we have to find the cart total and receipt total to be used
    // in the table

    var queries = [
        {
            field: 'bookingNo',
            value: request.bookingNo,
        },
        {
            field: 'dealerCode',
            value: request.dealerCode,
        },
    ];

    return Cart.get(queries).then(
        // We have the cart items as an array,
        // We have to add them and assign it to array object

        (result) => {
            // let total = (cartItems).reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0)
            // let cartTotal = (cartItems).reduce((a, b) => a + (parseInt(b['price']) * (b['quantity']) || 1), 0)
            let cartTotal = 0;

            result.cart
                .filter((items) => {
                    return items.status !== 'removed' && items.status !== 'rejected';
                })
                .forEach((items) => {
                    // if (items.type !== 'Accessories' && items.type !== 'VAS') {
                    //     let repeat = arr.filter((i) => i.type === items.type);
                    //     if (!repeat.length) {
                    //         arr.push(items);
                    //     }
                    // } else {
                    arr.push(items);
                    //  }
                });

            return FirebaseUtils.getBillingDetails(request).then((billItems) => {
                if (billItems && billItems[0]) {
                    billItems
                        .filter((items) => {
                            return items.status !== 'removed' && items.status !== 'rejected';
                        })
                        .forEach((items) => {
                            arr.push(items);
                        });
                }

                var query = [
                    {
                        field: 'bookingNo',
                        value: request.bookingNo,
                    },
                    {
                        field: 'dealerCode',
                        value: request.dealerCode,
                    },
                ];

                return Bills.get(query).then((res) => {
                    if (res.bills && res.bills[0]) {
                        res.bills
                            .filter((items) => {
                                return items.cancellation_status !== true;
                            })
                            .forEach((items) => {
                                arr.push(items);
                            });
                    }

                    arr.forEach((items) => {
                        if (items.net_amount) {
                            cartTotal = cartTotal + items.net_amount;
                        } else if (!items.net_amount && items['price']) {
                            items.subItems &&
                                items.subItems.map((subitem) => {
                                    if (subitem.status !== 'removed') {
                                        cartTotal = cartTotal + subitem.price;
                                    }
                                });
                            if (!items.subItems) {
                                cartTotal = cartTotal + items['price'] * (items['quantity'] || 1);
                            }
                        }
                    });

                    return cartTotal;
                });
            });
        }
    );
}

/**
 * Load receipt total from accounts or dms receipts
 *
 * @param {*} request
 * @param {*} response
 * @returns
 */
function loadReceiptTotal(request, response) {
    var obj = {};

    var queries = [
        {
            field: 'Appointment No',
            value: request.bookingNo,
        },
    ];

    if (request.accounts && request.accounts.ac_code && request.accounts.ac_code.value && response) {
        return new Promise((resolve, reject) => {
            response.forEach((res) => {
                if (res.acount_pointer == request.accounts.ac_code.value) {
                    obj = res;
                }
            });

            var ledgerReceipts = obj.ledgers.filter((entry) => entry.Trn === -1);

            var paidRecipt = ledgerReceipts;

            var recivedtotal = paidRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);

            var clearedRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'R' || entry.OpAcPDCStatus === 'N');

            var clearedTotal = clearedRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);
            // var clearRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'E');

            // var pendingcleartotal = clearRecipt.reduce((a, b) => a + parseInt(b['Amount']), 0);

            var openingtotal = -obj.opening_balance;

            var totalBalance = recivedtotal + openingtotal;

            var total = {
                paidtotal: totalBalance,
                pendingcleartotal: totalBalance - (clearedTotal + openingtotal),
            };

            resolve(total);
        });
    } else {
        return new Promise((resolve, reject) => {
            return FirebaseUtils.getReceipts(request.city, queries).then((content) => {
                // We have the Receipt items as an array,
                // We have to add them and assign it to array object
                let receipts = content.receipts;

                let totalBalance = receipts.reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0);

                var total = {
                    paidtotal: totalBalance,
                    pendingcleartotal: 0,
                };
                resolve(total);
            });
        });
    }
}
