import React, { useState, useEffect, useMemo } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import CreateUser from './../create-user/create-user';
import EditUser from './../edit-user/edit-user';

import { Link } from 'react-router-dom';

import { message } from 'antd';

import { Tag, Badge } from 'antd';

import moment from 'moment';
import { dealerCodeKey } from '../global-config';
/**
 * Structure for Booking Listing and Add
 */
export const bookingColumns = [
    {
        type: 'input',
        caption: 'Booking No',
        field: 'Booking No',
    },
    {
        field: 'Name of the Customer',
        type: 'input',
        caption: 'Customer Name',
    },
    {
        field: 'Contact Number',
        type: 'input',
        caption: 'Contact Number',
    },
    {
        field: 'Booking Date',
        type: 'input',
        caption: 'Booking Date',
    },

    {
        caption: 'KEC',
        field: 'Consultant Name',
        type: 'input',
    },

    {
        caption: 'Request',
        field: 'requestId',
        type: 'input',
    },
];

/**
 * Schema for the table
 */
export const bookingSchema = {
    dateField: 'bookingDate',

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],
};

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Phone',
        field: 'customer.phone',
    },
    // {
    //     type: 'tag',
    //     caption: 'Status',
    //     field: 'status',
    // },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];

export const requestSchema = {
    queries: [],
};

/**
 * Schema used for Import users
 */
export const userSchema = {
    tableIndex: (element) => {
        return element['Name'] + '-' + element['Role'];
    },
    rowIndex: 'Name',
    importAll: true,
    tableHeader: 2,
    tableRange: 0,
    isDirect: true,
    disableSelect: true,
    Add: CreateUser,
    Edit: EditUser,

    columns: [
        {
            type: 'input',
            caption: 'NAME',
            field: 'name',
        },
        {
            field: 'phone',
            type: 'input',
            caption: 'Phone',
        },
        {
            field: 'role',
            type: 'input',
            caption: 'Role',
        },
        {
            caption: 'Place',
            field: 'place',
            type: 'input',
        },

        {
            caption: 'Email',
            field: 'email',
            type: 'input',
        },
    ],
};

export const adminSteps = [
    // Task Routes

    {
        caption: 'Approve for Billing',
        path: '/billing-approval',
        redirect_to: 'billing-approval-form',
        queries: [
            {
                field: 'billing.status',
                value: 'requested',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },

    {
        caption: 'Approve for Delivery',
        path: '/delivery-approval',
        redirect_to: 'delivery-approval-form',
        queries: [
            {
                field: 'delivery.status',
                value: 'requested',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: RequestCard,
    },

    // {
    //     caption: 'Approve for Cancellation',
    //     path: '/cancellation-approval',
    //     redirect_to: 'cancellation-approval-form',
    //     model: 'cancellations',
    //     queries: [
    //         {
    //             field: 'status',
    //             value: 'Refund Approval Pending'
    //         }
    //     ],
    //     Card: CancelCard
    // },

    // Billing Form
    {
        isForm: true,
        caption: 'Billing Approval Form',
        path: '/billing-approval-form/:city/:id',
        model: 'billing',
        post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                approved_at: moment().format('DD/MM/YYYY HH:mm'),
                approved_date: moment().startOf('day').valueOf(),
                approved_time: moment().valueOf(),
            };

            return {
                billing: {
                    ...userRequest.billing,
                    ...params,
                    ...creation,
                },
            };
        },
    },

    // Delivery Form
    {
        isForm: true,
        caption: 'Delivery Approval Form',
        path: '/delivery-approval-form/:city/:id',
        model: 'delivery',
        post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                approved_at: moment().format('DD/MM/YYYY HH:mm'),
                approved_date: moment().startOf('day').valueOf(),
                approved_time: moment().valueOf(),
            };

            return {
                delivery: {
                    ...userRequest.delivery,
                    ...params,
                    ...creation,
                },
            };
        },
    },

    // Cancel Form
    {
        isForm: true,
        caption: 'Cancellation Approval Form',
        path: '/cancellation-approval-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        // post_status: 'approved',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                refund_approved_at: moment().format('DD/MM/YYYY HH:mm'),
                refund_approved_date: moment().startOf('day').valueOf(),
                refund_approved_time: moment().valueOf(),
                status: 'Refund Payment Pending',
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: 'Refund Payment Pending',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },

    // Active Allocations
    {
        caption: 'Active Allocations',
        path: '/active-allocations',
        redirect_to: 'active-allocation-form',
        model: 'requests',

        queries: ({ user }) => {
            return [
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Allocated'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up for a quick retail',
        path: '/active-allocation-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // DMS Billing Allocations
    {
        caption: 'DMS Billed Cases',
        path: '/dms-billed-cases',
        redirect_to: 'actual-billing-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'billing.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Invoiced'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/actual-billing-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Disabled temporarily has unlinking delivery note changes are done
    // Delivery Note Taken Cases
    // {
    //     caption: 'Delivery Note Taken',
    //     path: '/delivery-note-taken',
    //     redirect_to: 'delivery-note-taken-form',
    //     model: 'requests',
    //     queries: ({ user }) => {
    //         return [
    //             {
    //                 field: 'delivery.status',
    //                 operator: 'in',
    //                 value: ['requested', 'approved', 'Delivery Note Taken'],
    //             },
    //             {
    //                 field: 'actual_delivery.status',
    //                 value: 'pending',
    //             },
    //             {
    //                 field: 'status',
    //                 operator: '!=',
    //                 value:'removed'
    //             },
    //         ];
    //     },
    //     Card: RequestCard,
    // },

    // Delivery Note Taken Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/delivery-note-taken-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Delivery Pending Cases
    {
        caption: 'Delivery Pending',
        path: '/delivery-pending-cases',
        redirect_to: 'delivery-pending-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'actual_delivery.status',
                    operator: 'in',
                    value: ['pending', 'Settlement Pending', 'Settlement Ready', 'Delivery Pending'],
                },
                {
                    field: 'actual_billing.status',                   
                    value: 'Invoiced'
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Delivery Pending Form
    {
        isForm: true,
        caption: 'Follow up for a quick delivery',
        path: '/delivery-pending-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },
];

export const stockColumns = [
    {
        type: 'input',
        caption: 'Vin Number',
        field: 'Vin Number',
    },
    {
        field: 'Variant',
        type: 'input',
        caption: 'Variant',
    },
    {
        field: 'Stock Status',
        type: 'input',
        caption: 'Stock Status',
    },
    {
        field: 'Exterior Color Name',
        type: 'input',
        caption: 'Color',
    },

    {
        caption: 'Color Type',
        field: 'Color Type',
        type: 'input',
    },

    {
        caption: 'Variant Code',
        field: 'Variant Code',
        type: 'input',
    },

    {
        caption: 'Invoice Date',
        field: 'KMI Invoice Date',
        type: 'input',
    },

    // {
    //     caption: 'Soft Allocation',
    //     field: 'allocated.requestId',
    //     type: 'input',
    // },

    {
        caption: 'Status',
        field: 'update',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const stockSchema = {
    includeFirstRow: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Vin Number',
    importAll: true,

    rowIndex: 'Vin Number',
    columns: [
        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],

    custom_actions: [
        {
            onClick: (selected, city, callback) => {
                console.log('jhell', selected);

                Promise.all(
                    selected.map((entry) => {
                        var count = 0;

                        return FirebaseUtils.unblockVehicle(entry).then(() => {
                            count++;
                            console.log(count);
                        });
                    })
                ).then(() => {
                    message.success('Action done');

                    callback();
                });
            },
            caption: 'Reset',
            multiple: true,
        },
    ],
};

/**
 * Structure for Parts Listing and Add
 */
export const partsColumns = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'partNo',
    },
    {
        field: 'partName',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier',
    },
    {
        field: 'price',
        type: 'input',
        caption: 'Price',
    },

    {
        caption: 'Model',
        field: 'model',
        type: 'radio',
        options: ['Seltos', 'Sonet', 'Carnival','Carens','EV6'], // This has to come from db
    },

    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['Accessories', 'VAS', 'After Market', 'Extended Warranty'],
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radio',
        options: ['Yes', 'No'],
    },

    {
        caption: 'CGST',
        field: 'cgst',
        type: 'input',
    },

    {
        caption: 'SGST',
        field: 'sgst',
        type: 'input',
    },

    {
        caption: 'IGST',
        field: 'igst',
        type: 'input',
    },

    {
        caption: 'Additional Tax',
        field: 'addition_tax',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const partsSchema = {
    tableHeader: 2,
    tableRange: 0,
    uploadIndex: 'PART NO', // Do we required an extra column like this
    tableIndex: 'partNo',
    custom_actions: [],
    rowIndex: 'partNo',
    columns: [
        {
            field: 'PART NO',
            from: 'PART NO',
        },

        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],
};

function RequestCard({ request, step }) {
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

function CancelCard({ request = {}, index, step }) {
    let city = dealerCodeKey[request.dealerCode]

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customerName}</h2>
                <h4 className="title">Phone No : {request.phone}</h4>
                <h3 className="title ">Type : {request.type}</h3>
                <a href={`/bookings/${request.bookingId}`} style={{ zIndex: 1 }}>
                    {request.bookingId}
                </a>
                <h4>{request.remarks}</h4>
                <Tag color="purple">{request.status || 'Initial'}</Tag>
                Created at {request.approve_at}
            </div>
        </Link>
    );
}
