import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Row, Tag, Image } from 'antd';

import { Link } from 'react-router-dom';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import RequestForm from './../../../common/resources/request-form/request-form';

import RecordForm from './../../../common/resources/record-form/record-form';

import BookingForm from './../../../kec/components/booking-form/booking-form';

import BaseForm from './../../../common/resources/base-form/base-form';

import moment from 'moment';
import GetUserName from '../../../../components/get-username/get-username';

// import './review-request-detail.scss';

export default function TaskForm({ match, step, history }) {
    const { user, disableDisplayPhoneNumber } = useContext(GlobalContext);

    const city = user.locations[0];

    const [userRequest, setuserRequest] = useState({ finance: {}, comments: [] });

    const { params } = match;

    const { id } = params;

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoader(true);

        if (step.endpoint === 'cancellations') {
            FirebaseUtils.getBaseRecord(id, step.model).then((request) => {
                setuserRequest(request);
                setLoader(false);
            });
        } else if (step.endpoint == 'bookings') {
            setLoader(false);
        } else if (step.endpoint == 'enquiries') {
            FirebaseUtils.getBaseRecord(id, step.model).then((request) => {
                setuserRequest(request);
                setLoader(false);
            });
        } else {
            FirebaseUtils.getRecord(step.endpoint || 'requests', id).then((result) => {
                var request = result;

                // console.log(request);
                // if (request.delivery) {
                //     if (request.delivery.scheduled_delivery_date) {
                //         request.delivery.scheduled_delivery_date = moment(request.delivery.scheduled_delivery_date, 'DD/MM/YYYY');
                //     }
                //     if (request.delivery.scheduled_delivery_time) {
                //         request.delivery.scheduled_delivery_time = moment(request.delivery.scheduled_delivery_time, 'HH:mm A');
                //     }
                // }

                console.log(request);

                setuserRequest(request);

                setLoader(false);
            });
        }
    }

    // Variable to prepopulate the form content for
    // document verification task
    let tlFormContent = {
        id: userRequest.id,

        actual_billing: {
            // bank: userRequest.finance.bank,
            ...userRequest.actual_billing,
        },
        finance: {
            ...userRequest.finance,
        },
    };

    // Variable to prepopulate the form content for
    // billing approval task
    let smFormContent = {
        id: userRequest.id,

        actual_billing: {

            ...userRequest.actual_billing,
        },
        finance: {
            ...userRequest.finance,
        },
    };

    return (
        <div className="">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    {step.endpoint === 'stock_transfers' ? (
                        <>
                            {/* Vehicle Card */}
                            <div className="vehicle-card">
                                <div className="vehicle-image-wrapper">{/* <img src={images[file['model']]} alt="vehicle-img" /> */}</div>
                                <div gutter={0} className="detail-wrapper">
                                    {/* <Tag color="purple">
                                                {userRequest.status || 'Initial'}
                                            </Tag> */}
                                    {step.model === 'stock_transfers' ? null : <div className="detail-element">
                                        <Row span={12}>
                                            <span>Booking No</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['bookingNo']}</h3>
                                        </Row>
                                    </div>}
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>From</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['from']}</h3>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>To</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['to']}</h3>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Vin Number</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{userRequest['vinNo']}</h3>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>Created By</Row>
                                        <Row span={12}>
                                            <h3 className=""><GetUserName user_id={userRequest['created_by']} /></h3>
                                        </Row>
                                    </div>
                                    <div className="detail-element">
                                        <Row span={12}>Created At</Row>
                                        <Row span={12}>
                                            <h3 className="">{userRequest['created_at']}</h3>
                                        </Row>
                                    </div>
                                    {step.model === 'stock_transfers' ? null : <div className="detail-element">
                                        <Row span={12}>View Request</Row>
                                        <Row span={12}>
                                            <h3 className="">
                                                <Link to={`/requests/${userRequest.id}`}>View Request</Link>
                                            </h3>
                                        </Row>
                                    </div>}
                                    <div className="detail-element">
                                        <Row span={12}>Remarks</Row>
                                        <Row span={12}>
                                            <h3 className="">{userRequest['remarks']}</h3>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : // for cancellation details section
                        step.endpoint === 'cancellations' ? (
                            <>
                                <div className="vehicle-card">
                                    <div className="about-us page-content">
                                        <h1 className="page-heading">Cancel {userRequest.type}</h1>
                                    </div>
                                    <p>Update any necessary information and see the status of your allocation below</p>
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <Row span={12}>
                                                    <h3>Customer Name </h3>
                                                </Row>
                                            </Row>
                                            <Row span={12}>
                                                <h2 className="title amount">{userRequest.customerName}</h2>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Email :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.email}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Booking No </h3>
                                            </Row>
                                            <Row span={12}>
                                                <a href={`/bookings/${userRequest.bookingId}`}>{userRequest.bookingId}</a>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Dealer Code :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.dealerCode}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Phone No :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.phone}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Attachments :</h3>
                                            </Row>
                                            <>
                                                {userRequest.cancellation_request &&
                                                    userRequest.cancellation_request.map((file) => {
                                                        return (
                                                            <>
                                                                {file.type === 'application/pdf' ? (
                                                                    <embed src={file.url} width="500" height="375" type="application/pdf" />
                                                                ) : (
                                                                    <Image width={200} src={file.url} />
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                            </>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Reason :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.reason}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.remarks}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Status </h3>
                                            </Row>
                                            <Row span={12}>
                                                <span>{userRequest.status}</span>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : step.endpoint === 'bookings' ? (
                            <>
                                <BookingForm match={match} step={step} history={history} />
                            </>
                        ) :
                            step.endpoint === 'enquiries' ? (
                                <>
                                    <div className="vehicle-card">
                                        <div className="about-us page-content">
                                            <h1 className="page-heading">Follow Up Enquiries</h1>
                                        </div>

                                        <p>Update any necessary information and see the status of your enquiries below</p>

                                        <div gutter={0} className="detail-wrapper">
                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <Row span={12}>
                                                        <h3>Customer </h3>
                                                    </Row>
                                                </Row>
                                                <Row span={12}>
                                                    <h2 className="title amount">{userRequest['Name of the Customer']} </h2>
                                                </Row>
                                            </div>
                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <h3>Contact Number</h3>
                                                </Row>
                                                <Row span={12}>
                                                <span>{userRequest['Contact Number']} <a className="card-action" href={`tel:${userRequest['Contact Number']}`} key="list-loadmore-edit">Call</a></span>

                                                   
                                                </Row>
                                            </div>                                
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="about-us page-content">
                                        <h1 className="page-heading">Allocation Request</h1>
                                    </div>
                                    <p>Update any necessary information and see the status of your request below</p>
                                    <div gutter={0} className="detail-wrapper">
                                        {/* 
                                                <Tag color="purple">
                                                    {userRequest.status}
                                                </Tag> */}
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <Row span={12}>
                                                    <h3>Customer </h3>
                                                </Row>
                                            </Row>
                                            <Row span={12}>
                                                <h2 className="title amount">{userRequest.customer.customerName} </h2>
                                            </Row>
                                        </div>
                                        <div className="detail-element">

                                            {/*disable PhoneNumber for kec and tl */}
                                            {!disableDisplayPhoneNumber ?
                                                <>
                                                    <Row span={12}>
                                                        <h3>Phone </h3>
                                                    </Row>
                                                    <Row span={12}>
                                                        <span>{userRequest.customer.phone}</span>
                                                    </Row>
                                                </>
                                                : null}
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Remarks </h3>
                                            </Row>
                                            <Row span={12}>
                                                <span>{userRequest.customer.remarks}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Variant </h3>
                                            </Row>
                                            <Row span={12}>
                                                <span>{userRequest.variant}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Color</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span>{userRequest.color}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Consultant :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.kec}</span>
                                            </Row>
                                        </div>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <h3>Vin :</h3>
                                            </Row>
                                            <Row span={12}>
                                                <span className="">{userRequest.allocation && userRequest.allocation.vinNo}</span>
                                            </Row>
                                        </div>

                                        <div className="detail-element">
                                            <Row span={12}>View Request</Row>
                                            <Row span={12}>
                                                <h3 className="">
                                                    <Link to={`/requests/${userRequest.id}`}>View Request</Link>
                                                </h3>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            )}
                    {step.endpoint === 'stock_transfers' ? (
                        <>
                            <RecordForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.endpoint === 'cancellations' ? (
                        <>
                            <BaseForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.endpoint === 'enquiries' ? (
                        <>
                            <BaseForm
                                index={id}
                                formContent={userRequest}
                                modelIndex="id"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.caption === 'Document Verification' ? (
                        <>
                            <RequestForm
                                formContent={tlFormContent}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.caption === 'Billing Approval' ? (
                        <>
                            <RequestForm
                                formContent={smFormContent}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    ) : step.endpoint === 'bookings' ? null : (
                        <>
                            <RequestForm
                                formContent={userRequest}
                                modelIndex="requestId"
                                model={step.model}
                                step={step}
                                callback={(params, values) => {
                                    history.goBack();
                                }}
                            />
                        </>
                    )}
                </Fragment>
            )}
        </div>
    );
}
