import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { GlobalContext } from '../../../../Store';

import {
    Table,
    Dropdown,
    Menu,
    Typography,
    Button,
    Space,
    Progress,
    Tag
} from 'antd';

import { GetData, PostData } from './../../../../utils/http.utils';

import FirebaseUtils from './../../../../utils/firebase.utils';

import { dealerCodeKey } from "../../../../modules/global-config";

export default function OrderTracking({ history, report, index }) {
    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [location, setLocation] = useState(user.locations[0]);

    const [accessories, setAccessories] = useState([]);

    useEffect(() => {

        getData();

        // FirebaseUtils.getAPIData().then((vehicles) => {
        //     // setLoader(false);
        //     console.log(vehicles);
        //     // setAllocations(vehicles.requests);
        // });
    }, []);

    const getData = async () => {
        let formBody = {
            location: location
        }
        let payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: formBody ? JSON.stringify(formBody) : null
        };

        // development url :- http://localhost:5001/medical-5866b/us-central1/getAccessoriesAvailableCount
        return fetch('https://us-central1-gbusiness-1601789339241.cloudfunctions.net/getAccessoriesAvailableCount', payload)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);

                setAccessories(res);

            })
            .catch((error) => {
                console.log('error api response', error);
                return;
            });
    }

    return (
        <div className="order-tracking listing">
            <TableView data={accessories}/>
        </div>
    )
}

function TableView({ data, callback }) {
    function showDrawer(item) {
        callback(item);
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1
        },
        {
            title: 'Part No',
            dataIndex: 'no',
            render: (text, record) => {
                let city = dealerCodeKey[record.dealerCode]
                return (
                    <Space size="middle">
                        <Link
                            style={{ fontSize: '12px' }}
                            to={`/${city}/accessories/${text}`}
                        >
                            {text ? text : record['partNo']}
                        </Link>
                    </Space>
                );
            },
            // sorter: (a, b) => {
            //     if (a && a.request && b && b.request) {
            //         return (
            //             a.request.allocation.status.length -
            //             b.request.allocation.status.length
            //         );
            //     } else if (a && a.request) {
            //         return a.request.allocation.status.length - 0;
            //     } else if (b && b.request) {
            //         return 0 - b.request.allocation.status.length;
            //     } else {
            //         return 0 - 0;
            //     }
            // },
            // defaultSortOrder: 'descend',
            // sortDirections: ['descend'],
            width: 200
        },
        {
            title: 'Part Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div style={{ fontSize: '12px' }}>
                        {text ? text : record['description']}
                    </div>
                );
            },
            width: 200
        },
        {
            title: 'Price',
            dataIndex: 'price'
        },
        {
            title: 'Category',
            dataIndex: 'type'
        },
        {
            title: 'No. of bookings',
            dataIndex: 'bookedCount'
        },
        {
            title: 'Available',
            dataIndex: 'available'
        }

    ];

    return (
        <Table
            width="100%"
            size="small"
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            pagination={false}
        ></Table>
    );
}