/**
 *
 * A Configuration File that would move to backend
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import { Link } from 'react-router-dom';

import { Vehicles, Requests, StatusLogs, StockTransfers } from '../../models';

import { message } from 'antd';

import { Tag, Badge, Space } from 'antd';

import moment from 'moment';

import StatusChange from '../../components/status-change/status-change';

import StatusLog from '../../models/status_logs';
import { cityKeys, dealerCodeKey } from '../global-config';
let next_follow_up_date = moment.tz('Asia/Calcutta').startOf('day').valueOf()


function RequestCard({ request, step }) {
    console.log(request);
    return (
        <Link className="request-card" key={request.id} to={`/${step.redirect_to}/${request.city}/${request.id}`}>
            <div className="card">
                <h2 className="title amount ">{request.customer.customerName}</h2>
                <h4 className="title">{request.customer.phone}</h4>
                <h3 className="title ">
                    {request.variant} , {request.city}
                </h3>
                <h4>{request.color}</h4>
                <p className="address">
                    {request.customer.remarks}
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                </p>
                Created at {request.created_at}
            </div>
        </Link>
    );
}

function VehicleCard({ vehicle, step }) {
    return (
        <div className="request-card">
            <div className="card">
                <h2 className="title amount">{vehicle['Vin Number']}</h2>

                {vehicle['Cust Name'] ? (
                    <h3 className="title ">
                        Customer : {vehicle['Cust Name']} - {vehicle['Cust ID']}
                    </h3>
                ) : null}

                <h3 className="title ">Stock Location : {vehicle['Stock Location']}</h3>
                <h4 className="title">Request Dealer Code : {vehicle.allocated.request_dealerCode}</h4>
                <h4>Request Location : {dealerKey[vehicle.allocated.request_dealerCode]}</h4>
                <h4>Vehicle Dealer Code : {vehicle.dealerCode}</h4>
            </div>
        </div>
    );
}

var cityKey = cityKeys

var dealerKey = dealerCodeKey

export const smSteps = [
    // Active Allocations
    {
        caption: 'Active Allocations',
        path: '/active-allocations',
        redirect_to: 'active-allocation-form',
        model: 'requests',

        queries: ({ user }) => {
            return [
                {
                    field: 'allocation.status',
                    operator: 'in',
                    value: ['requested', 'approved', 'Allocated'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up for a quick retail',
        path: '/active-allocation-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // DMS Billing Allocations
    {
        caption: 'DMS Billed Pending',
        path: '/dms-billed-cases',
        redirect_to: 'actual-billing-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'billing.status',
                    operator: 'in',
                    value: ['pending', 'requested', 'approved'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'Invoiced',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/actual-billing-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Disabled temporarily as the new changes for unlinking delivery note is done
    // Delivery Note Taken Cases
    // {
    //     caption: 'Delivery Note Taken',
    //     path: '/delivery-note-taken',
    //     redirect_to: 'delivery-note-taken-form',
    //     model: 'requests',
    //     queries: ({ user }) => {
    //         return [
    //             {
    //                 field: 'delivery.status',
    //                 operator: 'in',
    //                 value: ['requested', 'approved', 'Delivery Note Taken'],
    //             },
    //             {
    //                 field: 'actual_delivery.status',
    //                 value: 'pending',
    //             },
    //             {
    //                 field: 'status',
    //                 operator: '!=',
    //                 value:'removed'
    //             },
    //         ];
    //     },
    //     Card: RequestCard,
    // },

    // Delivery Note Taken Form
    {
        isForm: true,
        caption: 'Follow up do the actual billing',
        path: '/delivery-note-taken-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Delivery Pending Cases
    {
        caption: 'Delivery Pending',
        path: '/delivery-pending-cases',
        redirect_to: 'delivery-pending-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'actual_delivery.status',
                    operator: 'in',
                    value: ['pending', 'Settlement Pending', 'Settlement Ready', 'Delivery Pending'],
                },
                {
                    field: 'actual_billing.status',
                    value: 'Invoiced'
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,

        // columns to be shown in table
        columns: [
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => index + 1,
                disableExport: true
            },
            {
                title: 'Name',
                dataIndex: 'Name',

                render: (record, item, index) => {
                    return item.customer.customerName;

                },
                //For download data
                exportDefinition: (record) => {

                    return record.customer.customerName;
                },
            },

            {
                title: 'Customer Phone',
                dataIndex: 'phone',

                render: (record, item, index) => {
                    return item.customer.phone
                },
                //For download data
                exportDefinition: (record) => {
                    return record.customer.phone;
                },
            },
            {
                title: "Vin Number",
                dataIndex: "Vin Number",
                render: (record, item, index) => {
                    return item.allocation.vinNo
                },
                //For download data
                exportDefinition: (record) => {
                    return record.allocation.vinNo;
                },
            },
            {
                title: 'Variant',
                dataIndex: 'Variant',

                render: (record, item, index) => {
                    return item.variant;

                },
                //For download data
                exportDefinition: (record) => {

                    return record.variant;
                },
            },
            {
                title: 'Color',
                dataIndex: 'Color',

                render: (record, item, index) => {
                    return item.color;

                },
                //For download data
                exportDefinition: (record) => {

                    return record.color;
                },
            },
            {
                title: 'Action',
                key: 'action',
                disableExport: true,
                render: (text, item, index) => {
                    return (
                        <Space size="middle">
                            <Link to={`delivery-pending-form/${item.city}/${item.id}`}>View</Link>
                        </Space>
                    );
                },

            }
        ]
    },

    // Delivery Pending Form
    {
        isForm: true,
        caption: 'Follow up for a quick delivery',
        path: '/delivery-pending-form/:city/:id',
        endpoint: 'requests',
        model: 'requests',
        post_status: 'Allocated',
        fields: [],
    },

    // Stock Transfer List
    {
        caption: 'Stock Transfer Requests',
        path: '/stock-transfer',
        redirect_to: 'stock-transfer-form',
        model: 'stock_transfers',
        filter: (result) => {
            return result.filter((ele) => {

                if (ele.mode) {

                    // If mode is "delivery" or "external-dealer-stock-transfer", then it is not listed here.
                    if (!["delivery", "external-dealer-stock-transfer"].includes(ele.mode)) {
                        return ele;
                    }

                } else {
                    return ele
                }

            })
        },
        queries: ({ user }) => {
            return [
                {
                    field: 'from',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'requested',
                },
                {
                    field: 'sub_mode',
                    value: 'normal'
                }
            ];
        },
        Card: TransferCard,
    },

    {
        caption: 'Virtual Stock Transfer Requests',
        path: '/virtual-stock-transfers',
        redirect_to: 'stock-transfer-form',
        model: 'stock_transfers',

        queries: ({ user }) => {
            return [
                {
                    field: 'from',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'requested',
                },
                {
                    field: 'sub_mode',
                    value: 'virtual'
                }
            ];
        },
        Card: TransferCard,
    },

    {
        caption: 'Cancellation Requests',
        path: '/cancel-request',
        redirect_to: 'cancel-request-form',
        model: 'cancellations',
        queries: ({ user }) => {
            return [
                // {
                //     field: 'dealerCode',
                //     value: user.locations[0]
                // },
                {
                    field: 'status',
                    value: 'Cancellation Approval Pending',
                },
            ];
        },
        Card: CancelCard,
    },

    //Sales Manager Approval Pending
    {
        caption: 'Billing Approval',
        path: '/billing-approval',
        redirect_to: 'billing-approval-form',
        model: 'requests',
        queries: ({ user }) => {
            return [
                {
                    field: 'actual_billing.status',
                    value: 'Sales Manager Approval Pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        Card: RequestCard,
    },

    {
        caption: 'Enquiry Follow Up',
        path: '/follow-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },
                {
                    field: 'next_follow_up_date',
                    value: next_follow_up_date,
                },
            ];
        },
    },
    {
        caption: 'Missed Follow Up',
        path: '/missed-up',
        redirect_to: 'followup-form',
        model: 'enquiries',
        queries: ({ user }) => {
            return [
                {
                    field: 'enquiry_status',
                    value: 'LIVE',
                },

                {
                    field: 'next_follow_up_date',
                    operator: '<',
                    value: next_follow_up_date,
                },
            ];
        },
    },

    {
        isForm: true,
        caption: 'Enquiry Follow Up',
        endpoint: 'enquiries',
        path: '/followup-form/:city/:id',
        model: 'enquiries',

        fields: [
            {
                type: 'follow_up_status',
                caption: 'Status',
                field: 'follow_up_status',
            },
        ],
    },
    //Sales Manager Approval Pending
    {
        isForm: true,
        caption: 'Billing Approval',
        path: '/billing-approval-form/:city/:id',
        model: 'actual_billing',
        //  post_status: 'Waiting For Management Approval',
        endpoint: 'requests',
        fields: [
            {
                type: 'input',
                caption: 'Insurance Nominee',
                field: 'insurance_nominee',
            },

            {
                type: 'select',
                caption: 'Insurance',
                field: 'insurance',
                options: ['Inhouse', 'Outhouse'],
            },

            {
                type: 'select',
                caption: 'Relation',
                field: 'relation',
                options: ['Mother', 'Father', 'Sister', 'Brother', 'Husband', 'Wife', 'Son', 'Daughter'],
            },

            {
                type: 'number',
                caption: 'Accessories',
                field: 'accessories_amount',
            },

            {
                type: 'reference-selector',
                caption: 'RTO',
                field: 'rto_id',
                table: 'rto_master',

                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        rto: selected.name,
                    };
                },
            },

            {
                type: 'reference-selector',
                caption: 'Bank',
                field: 'bank_id',
                table: 'finance_banks',
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        bank: selected.name,
                    };
                },
            },

            {
                type: 'reference-selector',
                caption: 'Branch',
                field: 'branch_id',
                queries: (values) => {
                    return [
                        {
                            field: 'bank_id',
                            value: values.bank_id,
                        },
                    ];
                },
                table: 'finance_branches',
                condition: (values) => {
                    return values.bank_id && values.type && values.type !== 'Own Fund';
                },
                // Additional support to override additional fields on
                // field change
                onUpdate: (field, record, selected) => {
                    return {
                        branch: selected.name,
                    };
                },
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'sm_remarks',
            },
        ],
        extraParams: (request, values) => {
            let creation = {
                sales_manager_approved_at: moment().format('DD/MM/YYYY HH:mm'),
                sales_manager_approved_date: moment().startOf('day').valueOf(),
                sales_manager_approved_time: moment().valueOf(),
            };

            let params;
            let params2;

            if (request && request.actual_billing && request.actual_billing.approvalRequired === false) {
                request.actual_billing.status = 'requested';
            } else {
                request.actual_billing.status = 'Waiting for Approval';
            }

            if (request.finance.type !== 'Own Fund') {
                params = {
                    ...creation,
                    ...request.finance,
                    bank: values.bank,
                    bank_id: values.bank_id,
                    branch: values.branch,
                    branch_id: values.branch_id,
                };

                params2 = {
                    ...request.actual_billing,
                    bank: values.bank,
                    bank_id: values.bank_id,
                    branch: values.branch,
                    branch_id: values.branch_id,
                    insurance_nominee: values.insurance_nominee,
                    relation: values.relation,
                    rto_id: values.rto_id,
                    rto: values.rto,
                    status: request.actual_billing.status,
                    sm_remarks: values.sm_remarks,
                };
            } else {
                params = {
                    ...creation,
                    ...request.finance,
                };

                params2 = {
                    ...request.actual_billing,
                    insurance_nominee: values.insurance_nominee,
                    relation: values.relation,
                    rto_id: values.rto_id,
                    rto: values.rto,
                    status: request.actual_billing.status,
                    sm_remarks: values.sm_remarks,
                };
            }

            return {
                actual_billing: {
                    ...params2,
                },
                finance: {
                    ...params,
                },
            };
        },
    },

    // Stock Transfer Form
    {
        isForm: true,
        caption: 'Approve/Reject Stock Transfer Request',
        path: '/stock-transfer-form/:city/:id',
        endpoint: 'stock_transfers',
        model: 'stock_transfers',
        post_status: 'Allocated',
        showGatePass: true,
        extraParams: (content, params) => {
            return {
                status: params.status === 'Yes' ? 'approved' : 'rejected',
            };
        },
        fields: [
            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Approve',
                field: 'status',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        onSubmit: async (content, value, setVisible, setBookingStatusModal,setBookingNo, callback) => {
            var app = FirebaseUtils.getApp();

            var batch = app.batch();

            if (value.status === 'No') {
                // Get Request record
                const query = [{
                    field: 'allocation.vinNo',
                    value: content.vinNo
                },  
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },]

                var request = await Requests.get(query)
                // If the vehicle is blocked, we are unblocking the vehicle here
                // But this step is not required for manual stock transfers as the vehicle is not blocked against any request
                const requestId = request.requests[0] && request.requests[0].id ? request.requests[0].id : null
                if (requestId)
                    await unblock(requestId, content.vinNo, batch)

                // // Create a record in status log indicating status updation
                // return StockTransfers.createStatusLog('STCKCANCELLED', content, batch, value).then((res) => {

                return StockTransfers.cancelStockTransferRequest(content, batch).then((res) => {
                    batch.commit().then(() => {

                        // update booking status to be visible 
                        setBookingNo(request.requests[0].bookingNo)
                        setBookingStatusModal(true)
                        // callback()
                    });
                });


            } else if (value.status === 'Yes') {



                return StockTransfers.createStatusLog('STCKAPPROVED', content, batch, value).then((res) => {
                    batch.commit().then(() => {

                        // Gate pass to be visible only in normal stock transfer
                        if (content.sub_mode === "normal") {
                            setVisible(true)
                        } else {
                            setVisible(false)
                            callback()
                        }
                    });
                });
            }
        },
    },

    // cancel request Form
    {
        isForm: true,
        caption: 'Approve/Reject Cancel Request',
        path: '/cancel-request-form/:city/:id',
        endpoint: 'cancellations',
        model: 'cancellations',
        // post_status: 'Allocated',
        extraParams: (userRequest, params, user) => {
            let creation = {
                status: params.status == 'Yes' ? 'CCM Approval Pending' : 'Declined',
            };
            if (params.status == 'Yes') {
                creation = {
                    ...creation,
                    approve_at: moment().format('DD/MM/YYYY HH:mm'),
                    approve_date: moment().startOf('day').valueOf(),
                    approve_time: moment().valueOf(),
                    approved_by: user.name,
                };
            } else {
                creation = {
                    ...creation,
                    reject_at: moment().format('DD/MM/YYYY HH:mm'),
                    reject_date: moment().startOf('day').valueOf(),
                    reject_time: moment().valueOf(),
                    rejected_by: user.name,
                };
            }

            return {
                ...params,
                ...creation,
            };
        },
        fields: [
            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Approve/Reject',
                field: 'status',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'sm_remarks',
            },
        ],
        onSubmit: (request, param) => {
            let params = {
                cancellation: {
                    id: request.id,
                    status: param.status == 'Yes' ? 'CCM Approval Pending' : 'Declined',
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, params).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },

    // Other location blocked vehicles
    // {
    //     caption: 'Other location blocked vehicles',
    //     path: '/other-location-blocked-list',

    //     model: 'vehicles',
    //     queries: (user) => {
    //         return [
    //             {
    //                 field: 'allocated.request_dealerCode',
    //                 operator: '!=',
    //                 value: cityKey[user.user.locations[0]],
    //             },
    //         ];
    //     },
    //     Card: VehicleCard,
    // },
];
// unallocate vehicle

function unblock(id, vinNo, batch) {

    // GEt request Record
    return Requests.getRecord(id).then((request) => {
        // Get Vehicle record
        Vehicles.getRecord(vinNo).then((vehicle) => {
            // Unblock the vehicle
            Vehicles.unblockVehicle(vehicle, request, batch).then(() => {
                message.success('This request has been rejected, Vehicle has been unblocked.');
            });
        });
    });
}

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Phone',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];

export const requestSchema = {
    queries: [],
};

/**
 * Structure for Booking Listing and Add
 */
export const bookingColumns = [
    {
        type: 'input',
        caption: 'Booking No',
        field: 'Booking No',
    },
    {
        field: 'Name of the Customer',
        type: 'input',
        caption: 'Customer Name',
    },
    {
        field: 'Contact Number',
        type: 'input',
        caption: 'Contact Number',
    },
    {
        field: 'Booking Date',
        type: 'input',
        caption: 'Booking Date',
    },

    {
        caption: 'KEC',
        field: 'Consultant Name',
        type: 'input',
    },

    {
        caption: 'Request',
        field: 'requestId',
        type: 'input',
    },
];

/**
 * Schema for the table
 */
export const bookingSchema = {
    dateField: 'bookingDate',

    pendingBookings: [
        {
            field: 'billing.status',
            value: 'pending',
            // operator: '!='
        },
    ],

    billingQueries: [
        {
            field: 'billing.status',
            value: 'done',
        },
        {
            field: 'delivery.status',
            value: 'pending',
        },
    ],

    deliveryQueries: [
        {
            field: 'delivery.status',
            value: 'done',
        },
    ],
};

function TransferCard({ city, vehicle = {}, index, step }) {
    return (
        <Link className="request-card" to={`/${step.redirect_to}/${vehicle.from}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.from + ' -> ' + vehicle.to}</h2>
                <h4 className="title">Vin No : {vehicle.vinNo}</h4>
                <h3 className="title ">Booking : {vehicle.bookingNo}</h3>
                <h4>{vehicle.remarks}</h4>
                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>
                Created at {vehicle.created_at} by {vehicle.created_by_name}
            </div>
        </Link>
    );
}

function CancelCard({ city, vehicle = {}, index, step }) {

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.customerName}</h2>
                <h4 className="title">Phone No : {vehicle.phone}</h4>
                <h3 className="title ">Type : {vehicle.type}</h3>
                <a href={`/bookings/${vehicle.bookingId}`} style={{ zIndex: 1 }}>
                    {vehicle.bookingId}
                </a>
                <h4>{vehicle.remarks}</h4>
                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>
                Created at {vehicle.created_at} by {vehicle.created_by_name}
            </div>
        </Link>
    );
}
