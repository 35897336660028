import React, { useEffect, useState, useContext } from 'react'

import { Link } from 'react-router-dom'

import { Tabs, Card, Space, Table, Typography, message, Button } from 'antd';
import { Select } from 'antd';

import { Modal, Form, Input } from 'antd';

import { Menu } from 'antd';


import { GlobalContext } from '../../../../Store';


import FirebaseUtils from '../../../../utils/firebase.utils';

import './manage-users.scss';
import { cityKeys } from '../../../global-config';
const { Text, Title } = Typography;


const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

const { TabPane } = Tabs;

const { Option } = Select;

export default function ManageUsers({ history, report, index }) {
    var cityKeysArray = Object.keys(cityKeys);

    const { user } = useContext(GlobalContext);

    const [result, setResults] = useState([]);

    const [visible, setVisible] = useState(false);

    const [selected, setSelected] = useState({});


    const [loading, setLoading] = useState(false);


    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    })

    const [page, setPage] = React.useState(1);

    const [location, setLocation] = useState(user.locations[0]);

    const [form] = Form.useForm();


    const columns = [{
        title: 'Index',
        dataIndex: 'index',
        render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Team Leader',
        // dataIndex: 'teamLeader',
        render: (text, row, index) => {
            return text && text.dms && text.dms.teamLeader;
        },
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        // render: (text, row, index) => {
        //     return text.phone;
        // },
        key: 'phone',
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
    }, {
        title: 'Locations',
        dataIndex: 'locations',
        key: 'locations',
    }, {
        title: 'Place',
        dataIndex: 'place',
        key: 'place',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // render: (text, row, index) => {
        //     return text.name;
        // },
    },
    // {
    //     title: 'Created At',
    //     dataIndex: 'created_at',
    //     key: 'created_at',
    // }, 

    {
        title: 'Action',
        key: 'delete',
        render: (text, record) => {

            return (
                <Space size="middle">
                    <Button onClick={() => {

                        FirebaseUtils.deleteUser(text.userId).then(() => {

                            message.success('User Account Deleted');

                            getUsers(location);

                        })

                    }} type="secondary" size={'small'}>Delete</Button>
                </Space>
            )
        },
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => {

            return (
                <Space size="middle">
                    <Button onClick={() => {

                        setVisible(true);

                        setSelected(text);


                        console.log(text);

                    }} type="secondary" size={'small'}>Edit</Button>
                </Space>
            )
        },
    }
    ];

    useEffect(() => {
        if (user) {

            getUsers(location);
        }

    }, [user])

    const loadData = (index) => {

        let tab = user.locations[index];

        // getBookings(tab);
        setLocation(tab);

    }

    function getUsers(location) {

        FirebaseUtils.getUsers(location).then((result) => {

            console.log(result);

            let b = Object.keys(result).map((request) => {
                return { ...result[request], userId: request }
            });

            setResults(b);

        });
    }

    /**
 * 
 */
    function submitRequest() {

        setLoading(true);

        form
            .validateFields()
            .then(values => {

                console.log(values);

                let userContent = {
                    name: values.name,
                    email: values.email,
                    place: values.place,
                    role: values.role,
                    phone: values.phone,
                    locations: values.locations,
                    deleted_at:null,
                    deleted_by:null
                }

                if (['tl', 'kec'].indexOf(values.role) !== -1) {

                    userContent.dms = {
                        'Consultant Name': values.consultantName,
                        'teamLeader': values.teamLeader
                    }

                }

                if (selected.userId) {

                    // Create Profile With The key
                    FirebaseUtils.updateUserInfo(selected.userId, userContent).then(() => {

                        message.success('User profile updated!');

                        setVisible(false);

                        form.resetFields();

                        getUsers(location);

                    })


                } else {

                    // Create User Account
                    FirebaseUtils.createUser({ email: values.email, password: '123456' }).then((result) => {

                        console.log(result);

                        setLoading(false);

                        if (result.user) {

                            // Create Profile With The key
                            FirebaseUtils.addUserInfo(result.user, userContent).then(() => {

                                message.success('User profile created!');

                                setVisible(false);

                                form.resetFields();

                                getUsers(location);

                            })

                        } else {

                            message.error('Error');

                        }
                    }).catch(info => {

                        message.error('User creation failed');

                        console.log('Validate Failed:', info);

                        setLoading(false);

                    });

                }

            })
            .catch(info => {
                console.log('Validate Failed:', info);

                setLoading(false);

            });
    }


    /**
     * 
     */
    function closeModal() {

        setSelected({});

        setVisible(false);
    }


    return (
        <div className="manage-requests">


            <div className="page-header">

                <Title level={4}>
                    Users
                </Title>
                {/* <p>Import your dms file and mention the reference to upload it !</p> */}

                <div>


                    {/* <Text type="secondary">{`${result.length} records`}</Text> */}

                    <Button onClick={() => {

                        setVisible(true);


                    }} type="primary" size={'small'}>Add</Button>
                </div>

            </div>


            <Card size="small">
                <Table

                    rowKey={record => record.index}
                    scroll={{ x: 'scroll' }}
                    dataSource={result}
                    columns={columns}>

                    <Table.Column
                        title="Index"
                        key="index"
                        render={(value, item, index) => (page - 1) * 10 + index}
                    />

                </Table>
            </Card>


            <Modal
                confirmLoading={loading}
                title="User Details"
                visible={visible}
                onOk={submitRequest}
                onCancel={closeModal}>

                <Form
                    form={form}
                    {...layout}
                    name="new-record"
                    layout="vertical"
                    // onFinish={onSubmit}
                    // validateMessages={validateMessages} 
                    initialValues={{
                        ...selected,
                        userId: selected.userId
                    }}>

                    <Form.Item name={'name'} label="Name" rules={[{ required: true, message: 'Please enter Name' },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={'place'} label="Place" rules={[{ required: true, message: 'Please enter Place' },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={'phone'} label="Phone Number" rules={[{ required: true, message: 'Please enter Customer Phone Number' },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={'email'} label="Email" rules={[{ required: true, message: 'Please enter Email Address' },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={'locations'} label="Locations" rules={[{ required: true, message: 'Please enter Locations' },]}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            // defaultValue={['a10', 'c12']}
                            onChange={() => { }}
                        >

                            {cityKeysArray.map((location, key) => {

                                return <Option value={location} key={key}>{location}</Option>
                            })}

                        </Select>
                    </Form.Item>

                    <Form.Item name={'role'} label="Role" rules={[{ required: true, message: 'Please enter role' },]}>

                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            // defaultValue={['a10', 'c12']}
                            onChange={() => { }}
                        >

                            {['admin', 'management', 'rm', 'sm', 'edp', 'finance', 'insurance', 'accessories', 'rto', 'warranty', 'tl', 'kec', 'accounts', 'delivery', 'pdi', 'receptionist','ccm'].map((role, key) => {

                                return <Option value={role} key={key}>{role}</Option>
                            })}

                        </Select>
                    </Form.Item>


                    {/*  Applicable for KEC and Teamleader */}


                    <Form.Item name={'consultantName'} label="Consultant Name" rules={[{ message: 'Please enter Consultant Name' },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={'teamLeader'} label="Team Leader Name" rules={[{ message: 'Please enter Consultant Name' },]}>
                        <Input />
                    </Form.Item>

                </Form>
            </Modal>

        </div>
    )
}
