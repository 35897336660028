import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Form, Input, DatePicker, InputNumber, message, Typography, Skeleton } from 'antd';

import { Select, Radio, Alert } from 'antd';

import AsyncSelect from 'react-select/async';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import FileUpload from '../../../../components/file-upload/file-upload';

import { CheckCircleOutlined } from '@ant-design/icons';

import './customer-receipts-add.scss';

import ApiUtils from './../../../../utils/api.utils';

import { Bookings, Enquirys, Pricing, Designations, Employees } from '../../../../models';

import moment from 'moment';
import { Departments, CustomerEntries } from '../../../../models';
import { param } from 'jquery';
import { cityKeys } from '../../../global-config';

const { Title } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TextArea } = Input;

const { Option } = Select;

var cityKey = cityKeys

export default function CustomerReceiptsAdd({ history, department }) {
    const { user = { locations: [] } } = useContext(GlobalContext);

    // const [modes, setModes] = useState([]);

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(true);

    const [type, setType] = useState('');

    const [mode, setMode] = useState('');

    const [loading, setLoading] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [emp, setEmp] = useState({});

    const [bookingNumber, setBookingNumber] = useState([]);

    const [booking, setBooking] = useState([]);

    var [variant, setVariant] = useState([]);

    const [choice, setChoice] = useState(booking[0]);

    const [kec, setKec] = useState([]);

    const [noBooking, setNoBooking] = useState(false);

    const [bookingAlert, setBookingAlert] = useState(false);

    const [enquirySelected, setEnquirySelected] = useState(false);

    const [departments, setDepartments] = useState([]);

    const [hide, setHide] = useState(true);

    const [selectedBooking, setSelectedBooking] = useState();

    const [load, setLoad] = useState(false);

    const [selected, setSelected] = useState();

    const [selectedBranch, setSelectedBranch] = useState();

    useEffect(() => {
        bootComponent();
    }, []);

    async function bootComponent() {
        await getDepartment();
    }

    function getEmployees(branch) {
        var queries = [
            {
                field: 'role',
                value: 'kec',
            },
            {
                field: 'active',
                value: 'true',
            },
        ];

        // if (['sm', 'tl', 'accounts'].indexOf(user.role) !== -1) {
        //     queries.push({
        //         field: 'locations',
        //         operator: 'array-contains',
        //         value: city,
        //     });
        // }

        var query = [
            {
                field: 'designation',
                value: 'KEC',
            },
        ];

        Designations.get(query).then((result) => {
            console.log(result);

            var queries = [
                {
                    field: 'designation',
                    value: result.designations[0].id,
                },
            ];

            Employees.get(queries).then((res) => {
                console.log(res);
                setKec(res.employees);
            });

            // FirebaseUtils.getEmployees(queries).then((result) => {
            //     setEmp(result);

            //     let b = Object.keys(result).map((booking) => {
            //         return { ...result[booking], ...{ userId: booking } };
            //     });

            //     setKec(b);
            //     console.log(result);
            // });
        });
    }

    /**
     *
     *
     * @returns
     */
    async function getDepartment() {
        var query = [
            {
                field: 'description',
                value: department[0],
            },

            {
                field: 'id',
                operator: 'in',
                value: user.department_ids,
            },
        ];
        return await Departments.get(query).then(async (res) => {
            setDepartments(res.departments);

            setSelectedBranch(res.departments[0]);

            await getEmployees(res.departments[0]);

            setLoader(false);
        });
    }

    /**
     *
     * For each change in status we update the log
     *
     * @param {*} values
     */
    const onSubmit = async (values) => {
        // let city = user.locations[0];

        console.log(values);

        // let selectedMode = modes.filter((m) => m.ac_code === values.mode).pop();

        let creation = {
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role,
            // created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            txn_date: moment().format('DD/MM/YYYY HH:mm'),
            transaction_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(), // this can be removed if not needed
        };

        if (values.type === 'Cheque') {
            values.chequeDate = moment(values.chequeDate).format('DD/MM/YYYY');
        }

        if (values.amount) {
            values.amount = parseFloat(values.amount);
        }

        if (!values.enquiryId) {
            delete values.enquiryId;
        }

        if (!values.bookingNo) {
            delete values.bookingNo;
        }

        if (!values.utr) {
            delete values.utr;
        }

        if (!values.ref) {
            delete values.ref;
        }

        let params = {
            ...creation,
            ...values,
            pending_at: 'edp',
            deleted_at: null,
            deleted_by: null,

            // When casier collects cash no need to enter to cs accounts
            // accounts_status: user.role === 'accounts' ? 'completed' : 'pending',
            accounts_status: 'pending',

            city: selectedBranch.location,
            status: 'submitted',
            source: user.role === 'finance' ? 'finance' : 'cashier',
            verified: user.role === 'finance' ? false : 'approved',
            dealerCode: cityKey[selectedBranch.location],
            department_id: selected,
            // branch_id: departments.branch_id,
            // department_name: departments.name,
        };

        if (!values.variant) {
            if (choice && choice.Variant) {
                params = {
                    ...params,
                    enquiryId: choice.id,

                    model: choice.Model,
                    variant: choice.Variant,
                };
            } else {
                params = {
                    ...params,
                    variant: null,
                };
            }

            if (choice && choice.Color) {
                params = {
                    ...params,
                    color: choice.Color,
                };
            } else {
                params = {
                    ...params,
                    color: null,
                };
            }
        }

        // if (values.kec) {

        //     kec.map((employee) => {

        //         if (employee.dms_name === values.kec) {

        //             params = {
        //                 ...params,

        //                 consultant_id: employee.id,
        //                 teamleader_id: employee.header_id,
        //             };
        //         }
        //     });
        // }

        if (choice && choice.consultant_id) {
            params = {
                ...params,
                consultant_id: choice.consultant_id,
            };
        }

        if (choice && choice.teamleader_id) {
            params = {
                ...params,
                teamleader_id: choice.teamleader_id,
            };
        }

        // kec.map((em) => {
        //     var teamLeader;

        //     if (em.dms['Consultant Name'] === values.kec && em.active === 'true') {
        //         teamLeader = em.dms['teamLeader'];

        //         params = {
        //             ...params,
        //             teamLeader: teamLeader,
        //         };
        //     }
        // });
        // if (department[0] !== 'Department Handling Services') {
        //     kec.map((em) => {
        //         var teamLeader;

        //         if (em.dms['Consultant Name'] === values.kec && em.active === 'true') {
        //             teamLeader = em.dms['teamLeader'];

        //             params = {
        //                 ...params,
        //                 teamLeader: teamLeader,
        //             };
        //         }
        //     });
        // }

        if (!values.model) {
            params = {
                ...params,
                model: choice.Model,
            };
        }

        if (choice && choice.id) {
            params = {
                ...params,
                enquiryId: choice.id,
                // variant: choice.Variant,
                // color: choice.Color,
                // model: choice.Model
            };
        }

        let details = params;
        // If its cash we have received the amount
        if (values.type === 'Cash' || values.type === 'CPO') {
            params.pending_to_reconcile = false;

            params.mode = form.getFieldValue().mode || '';
        } else {
            params.pending_to_reconcile = true;

            if (values.type === 'Cheque') {
                params = {
                    ...params,
                    ref: values.chequeNo,
                    chequeNo: values.chequeNo,
                    chequeDate: values.chequeDate,
                };
            } else {
                params = {
                    ...params,
                    ref: values.utr || '',
                };
            }
        }

        setLoader(true);

        console.log(params);

        // const hide = message.loading('Creating accounts entry, Please wait', 0);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        // Add a new collection
        var docRef = CustomerEntries.getRecordReference();

        console.log(docRef.id);

        await batch.set(docRef, params);

        //FirebaseUtils.addCollection(params).then((docRef) => {

        // Cashier Entry recorded
        params.v_othref = 'CE:' + docRef.id;


        // Not required without cs
        // Record in CS Accounts
        // return ApiUtils.createVoucher(params, selectedMode, selectedBranch)
        // .then(async ({ account_head, voucher }) => {
        // let entryParams = {
        //     voucher: {
        //         v_vno: voucher.v_vno,
        //         v_ids: voucher.v_ids,
        //     },
        // };

        ////On account of booking account_head is returned
        // if (account_head) {
        //     entryParams = {
        //         ...entryParams,
        //         ac_code: {
        //             label: account_head.ac_desc,
        //             value: account_head.ac_code,
        //         },
        //     };
        // } else {
        //     entryParams = {
        //         ...entryParams,
        //         ac_code: values.ac_code,
        //     };
        // }

        // Not required without cs
        // await batch.update(docRef, entryParams);

        var bookingParams = {};

        var ac_code;


        // Not required without cs
        // if (values.account === 'BOOKING') {
        //     ac_code = {
        //         ...entryParams.ac_code,
        //     };
        // } else {
        //     ac_code = {
        //         ...values.ac_code,
        //     };
        // }

        values.dealerCode = cityKey[selectedBranch.location];

        //get booking amount and receipt amount and update in bookings
        var updateValues = await Bookings.updateBookingFields(values);

        bookingParams = {
            //not required without cs
            // ac_code: ac_code, 
            ...updateValues
        };

        // Update the booking

        if (department[0] === 'Department Handling Sales') {
            //Bookings.update(cityKey[selectedBranch.location] + values.bookingNo, bookingParams).then(() => {
            // const bookingRef = Bookings.getRecordReference(cityKey[selectedBranch.location] + values.bookingNo);

            // await batch.update(bookingRef, bookingParams);

            return batch.commit().then(() => {
                // hide();

                message.success('Payment Collection Recorded');

                setLoader(false);

                history.push(`/${details.city}/customer-receipts/${docRef.id}`);
            });
        } else {
            return batch.commit().then(() => {
                // hide();

                message.success('Payment Collection Recorded');

                setLoader(false);

                history.push(`/${details.city}/customer-receipts/${docRef.id}`);
            });
        }
        // });
        // })
        // .catch(() => {
        //     // hide();
        //     // message.success('Payment Collection Recorded');
        //     // setLoader(false);
        //     // history.push(`/${details.city}/customer-receipts/${docRef.id}`);
        // });
        // });
    };

    /**
     * Get the receipt amount of the booking
     *
     * @param {*} values
     * @returns
     */
    function getReceiptAmount(values) {
        return Bookings.getRecord(cityKey[selectedBranch.location] + values.bookingNo).then((result) => {
            let existing_amount = result.receipt_amount || 0;
            return existing_amount + values.amount;
        });
    }

    function onUpload(attachments) {
        setDisabled(false);

        form.setFieldsValue({ attachments: attachments });
    }

    {
        /**
    Remove duplicate from an array
*/
    }
    function groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    }

    /**
     * Load the matching heads
     */
    // function loadHeads(query) {
    //     let branch = selectedBranch;
    //     return ApiUtils.getHeads(query, branch).then((result) => {
    //         console.log(result);
    //         return result.map((item) => {
    //             return {
    //                 value: item.ac_code,
    //                 label: item.ac_desc,
    //             };
    //         });
    //     });
    // }

    /**
     * Load the Modes
     */
    // function loadModes(query, branch) {
    //     if (query === 'Cash') {
    //         // return ApiUtils.getBranchRecord().then((record) => {
    //         form.setFieldsValue({
    //             mode: branch.acCodes['Cash'],
    //         });

    //         // console.log(record);
    //         //  });
    //     } else {
    //         return ApiUtils.getModes(query, branch).then((result) => {
    //             setModes(result);
    //         });
    //     }
    // }

    async function selectBranch(branchId, arg) {
        // form.resetFields();

        let matching = departments.filter((item) => item.id === branchId).pop();

        setSelectedBranch(matching);

        setSelected(branchId);

        await getEmployees(matching);

        form.setFieldsValue({ phone: null });

        setBookingNumber([]);

        setMode('');
    }

    /**
     *Get Data from Enquiry Table
     * */
    function getBookingNumber(phone) {
        let query = [
            {
                field: 'Contact Number',
                value: phone,
            },

            {
                field: 'dealerCode',
                value: selectedBranch.dealer_code,
            },
        ];

        return Bookings.get(query).then((result) => {
            if (result.bookings.length) {
                setBookingNumber(result.bookings);

                setLoading(false);

                setHide(false);

                setDisabled(false);

                return result;
            } else {
                setHide(true);

                setDisabled(true);

                setLoading(false);
            }
        });
    }

    {
        /**
         *
         *Get Variant list from pricing table
         *  */
    }

    function getVariant(model) {
        let query = [
            {
                field: 'model',
                value: model,
            },
        ];
        return Pricing.get(query).then((result) => {
            var model;

            model = groupBy(result.pricing, 'variant');

            setVariant(Object.keys(model));

            return variant;
        });
    }

    function getBooking(bookingno) {
        setNoBooking(false);
        setLoad(true);
        var query = [
            {
                field: 'bookingId',
                value: bookingno,
            },
        ];

        Bookings.get(query).then((res) => {
            if (res.bookings.length) {
                setBooking(res.bookings);
                setBookingAlert(false);
                setLoad(false);
            } else if (!res.bookings.length && bookingno.length >= 10) {
                setBookingAlert(true);

                setLoad(false);
            }
        });
    }

    var disable = false; //disable model and variant if present in enquiry
    var required = true;
    if (choice && choice.Model && choice.Variant) {
        disable = true;
        required = false;
    }

    /**
     * Set Choice to get model variant and booking number
     * @param {
     * } enquiry
     */

    function selectChoice(booking) {
        setEnquirySelected(true);
        setChoice(booking);

        if (!booking['Booking No']) {
            setNoBooking(true);
            setSelectedBooking(null);
        } else {
            setNoBooking(false);
            setSelectedBooking(booking['Booking No']);
        }

        if (booking['Booking No']) {
            var bookingNo = booking['Booking No'].substr(5);
        }

        var customerName = booking['Name of the Customer'];
        var model = booking['Model'];
        var variant = booking['Variant'];

        // var k = booking['Consultant Name'];
        // var KEC = kec.filter((item) => item.id === booking['consultant_id']);

        var KEC = booking['Consultant Name'];

        form.setFieldsValue({ bookingNo: bookingNo, name: customerName, model: model, variant: variant, kec: KEC });
    }

    function selectBookingChoice(book) {
        setSelectedBooking(book['Booking No']);

        var bookingNo = book['Booking No'].substr(5);
        form.setFieldsValue({ bookingNo: bookingNo });
    }

    return (
        <div className="allocation-form">
            {loader ? (
                <Skeleton type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="vehicle-card">
                            <>
                                <div className="document-status">
                                    <Title level={3}>Add Customer Receipt </Title>

                                    <p>For any customer payment , fill the details in the below form</p>

                                    {/* {selectedBranch.location !== city ? (
                                        <Alert
                                            style={{ margin: '10px 0px' }}
                                            message="Please select the correct branch to post "
                                            type="error"
                                            showIcon
                                        />
                                    ) : null} */}

                                    <Form
                                        {...layout}
                                        form={form}
                                        name="new-record"
                                        onFinish={onSubmit}
                                        initialValues={{
                                            variant: null,
                                            color: null,
                                        }}
                                        onFieldsChange={(fields, value) => {
                                            let field = fields[0];

                                            if (field && field.value) {
                                                // Type of Collection
                                                if (field.name[0] === 'type') {
                                                    setType(field.value);

                                                    // loadModes(field.value, selectedBranch);
                                                }

                                                if (field.name[0] === 'account') {
                                                    setMode(field.value);
                                                }

                                                if (field.name[0] === 'phone' && department[0] === 'Department Handling Sales') {
                                                    setLoading(true);
                                                    getBookingNumber(field.value);
                                                    form.setFieldsValue({ bookingNo: null });
                                                    setChoice(null);
                                                    setSelectedBooking(null);
                                                }
                                                if (field.name[0] === 'model') {
                                                    getVariant(field.value);
                                                }

                                                if (field.name[0] === 'bookingNo' && field.value.length >= 10) {
                                                    getBooking(field.value);
                                                } else if (field.name[0] === 'bookingNo' && field.value.length < 10) {
                                                    setBookingAlert(false);
                                                }
                                            } else {
                                            }
                                        }}
                                        layout="vertical"
                                    // validateMessages={validateMessages}
                                    >
                                        {department[0] === 'Department Handling Sales' ? (
                                            <Alert
                                                style={{ margin: '10px 0px' }}
                                                message="Select On Account of as Booking if this is a booking"
                                                type="info"
                                                showIcon
                                            />
                                        ) : null}

                                        <Form.Item
                                            name={'branch'}
                                            label="Select Branch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select Branch',
                                                },
                                            ]}
                                        >
                                            <Select
                                                onSelect={selectBranch}
                                                placeholder="Branch"
                                                style={{
                                                    width: '200px',
                                                    margin: '0px 10px',
                                                }}
                                            >
                                                {departments.map((doc, index) => (
                                                    <Option value={doc.id} key={doc.code}>
                                                        {doc.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name={'account'}
                                            label="On account of"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'On Account Of',
                                                },
                                            ]}
                                        >
                                            {department[0] === 'Department Handling Sales' ? (
                                                <Select>
                                                    {['BOOKING', 'PAYMENT', 'FINANCE', 'EXCHANGE', 'INSURANCE', 'ACCESSORIES'].map((option) => (
                                                        <Option value={option}>{option}</Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Select>
                                                    {['PAYMENT'].map((option) => (
                                                        <Option value={option}>{option}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        {mode || department[0] != 'Department Handling Sales' ? (
                                            <>
                                                <Form.Item
                                                    name={'phone'}
                                                    help="Dont start with zero"
                                                    label="Phone Number"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter phone',
                                                        },
                                                    ]}
                                                >
                                                    <Input prefix={'+91'} />
                                                </Form.Item>
                                            </>
                                        ) : null}

                                        {hide && disabled && !loading ? (
                                            <Alert
                                                style={{ margin: '10px 0px' }}
                                                message="Please enter the mobile number for which the enquiry is punched. An extra space or +91 added would not load the booking."
                                                type="error"
                                                showIcon
                                            />
                                        ) : null}

                                        {hide && department[0] === 'Department Handling Sales' ? null : (
                                            <>
                                                {loading && department[0] === 'Department Handling Sales' ? (
                                                    <Skeleton active />
                                                ) : bookingNumber.length > 0 && department[0] === 'Department Handling Sales' ? (
                                                    <>
                                                        {bookingNumber.length === 1 ? (
                                                            <div>
                                                                {' '}
                                                                There is 1 booking matching the given information. Please select the booking to have
                                                                model and variant of the Vehicle.
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                There are {bookingNumber.length} number of bookings matching the given information.
                                                                Please select the correct booking to have model and variant of the Vehicle.
                                                            </div>
                                                        )}

                                                        <div className="enquiry-option">
                                                            {bookingNumber.map((booking) => {
                                                                return (
                                                                    <div
                                                                        className={`enquiry ${booking === choice ? 'active' : ''}`}
                                                                        onClick={() => selectChoice(booking)}
                                                                    >
                                                                        <CheckCircleOutlined />
                                                                        Name: {booking['Name of the Customer']}
                                                                        <br />
                                                                        Variant: {booking.Variant}
                                                                        <br />
                                                                        Colour: {booking.Color}
                                                                        <br />
                                                                        Enquiry Date:{booking['Enquiry Date']}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                ) : null}

                                                {enquirySelected || department[0] != 'Department Handling Sales' ? (
                                                    <>
                                                        {department[0] === 'Department Handling Sales' ? (
                                                            <>
                                                                {' '}
                                                                <>
                                                                    <Form.Item
                                                                        name={'bookingNo'}
                                                                        label="Booking No"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter Booking Number',
                                                                            },
                                                                        ]}
                                                                        help="Please enter without dealer code"
                                                                    >
                                                                        <Input disabled={selectedBooking} />
                                                                    </Form.Item>

                                                                    {bookingAlert ? (
                                                                        <>
                                                                            <Alert
                                                                                style={{ margin: '10px 0px' }}
                                                                                message="Please enter correct booking number"
                                                                                type="warning"
                                                                                showIcon
                                                                            />
                                                                        </>
                                                                    ) : null}

                                                                    {load ? (
                                                                        <Skeleton active />
                                                                    ) : !noBooking && booking.length > 0 ? (
                                                                        <>
                                                                            {booking.length === 1 ? (
                                                                                <div>
                                                                                    {' '}
                                                                                    There is 1 booking matching the given booking number. Please
                                                                                    select the booking .
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    There are {booking.length} number of bookings matching the given
                                                                                    booking number. Please select the correct booking .
                                                                                </div>
                                                                            )}

                                                                            <div className="enquiry-option">
                                                                                {booking.map((booking) => {
                                                                                    return (
                                                                                        <div
                                                                                            className={`enquiry ${booking['Booking No'] === selectedBooking
                                                                                                ? 'active'
                                                                                                : ''
                                                                                                }`}
                                                                                            onClick={() => selectBookingChoice(booking)}
                                                                                        >
                                                                                            Booking Date: {booking['Booking Date']}
                                                                                            <br />
                                                                                            Variant: {booking['Variant']}
                                                                                            <br />
                                                                                            Colour: {booking['Color']}
                                                                                            <br />
                                                                                            KEC: {booking['Consultant Name']}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                                {noBooking ? (
                                                                    <>
                                                                        <Alert
                                                                            style={{ margin: '10px 0px' }}
                                                                            message="Sorry booking for this enquiry is not updated to surge yet.You can enter the booking number or you can continue once the dms booking is uploaded to surge"
                                                                            type="warning"
                                                                            showIcon
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}

                                                        {/* {mode === 'BOOKING' ? null : (
                                                            <>
                                                                <Form.Item
                                                                    name={'ac_code'}
                                                                    label="Account Head"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Select Account Head',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                                                                </Form.Item>
                                                            </>
                                                        )} */}
                                                        {department[0] === 'Department Handling Sales' ? (
                                                            <Form.Item
                                                                name={'enquiryId'}
                                                                label="Enquiry ID"
                                                                rules={[
                                                                    {
                                                                        message: 'Please enter enquiry id',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        ) : null}

                                                        <Form.Item
                                                            name={'name'}
                                                            label="Customer Name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter customer name',
                                                                },
                                                            ]}
                                                        >
                                                            <Input disabled={disable} />
                                                        </Form.Item>

                                                        {/* <Form.Item
                                                        name={'kec'}
                                                        label="KEC"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select model',
                                                            },
                                                        ]}
                                                    >
                                                        <Select allowClear style={{ width: '100%' }} placeholder="Please select" disabled={disable}>
                                                            {kec.map((kec) => {
                                                                return (
                                                                    // <Option value={kec.attributes.name} key={kec.id}>
                                                                    //     {kec.fname} - {kec.branch}
                                                                    // </Option>
                                                                    <Option value={kec.userId} key={kec.email}>
                                                                        {kec.name} - {kec.locations[0]}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item> */}

                                                        {department[0] === 'Department Handling Sales' ? (
                                                            <Form.Item
                                                                name={'kec'}
                                                                label="KEC"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select kec',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    allowClear
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Please select"
                                                                    disabled={disable}
                                                                >
                                                                    {kec.map((kec) => {
                                                                        return (
                                                                            <Option value={kec.dms_name} key={kec.id}>
                                                                                {kec.fname} - {kec.branch}
                                                                            </Option>
                                                                            // <Option value={kec.userId} key={kec.email}>
                                                                            //     {kec.name} - {kec.locations[0]}
                                                                            // </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        ) : null}

                                                        <Form.Item
                                                            name={'amount'}
                                                            label="Amount"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter Amount',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber min={0} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="model"
                                                            label="Model"
                                                            rules={[
                                                                {
                                                                    required: required,
                                                                    message: 'Please enter Model',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Please select Model"
                                                                disabled={disable}
                                                            >
                                                                {['SONET', 'SELTOS', 'CARNIVAL', 'CARENS', 'EV6'].map((option) => (
                                                                    <Option value={option}>{option}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="variant"
                                                            label="Variant"
                                                            rules={[
                                                                {
                                                                    // required: required,
                                                                    message: 'Please enter Variant',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Please select Variant"
                                                                disabled={disable}
                                                            >
                                                                {variant.map((variant) => {
                                                                    return <Option value={variant}>{variant}</Option>;
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={'type'}
                                                            label="Payment Type"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please choose the payment type.',
                                                                },
                                                            ]}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value="Cash">Cash</Radio>
                                                                <Radio value="Net Banking">Net Banking</Radio>
                                                                <Radio value="Credit Card">Credit Card</Radio>
                                                                <Radio value="Debit Card">Debit Card</Radio>
                                                                <Radio value="Cheque">Cheque</Radio>
                                                                <Radio value="Other Branch">Other Branch</Radio>
                                                                <Radio value="CPO">CPO</Radio>


                                                            </Radio.Group>
                                                        </Form.Item>
                                                        {/* {type && type !== 'Cash' ? (
                                                        <>
                                                            <Form.Item
                                                                name={'kec'}
                                                                label="KEC"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select kec',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    allowClear
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Please select"
                                                                    disabled={disable}
                                                                >
                                                                    {kec.map((kec) => {
                                                                        return (
                                                                            // <Option value={kec.attributes.name} key={kec.id}>
                                                                            //     {kec.fname} - {kec.branch}
                                                                            // </Option>
                                                                            <Option value={kec.userId} key={kec.email}>
                                                                                {kec.name} - {kec.locations[0]}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        ) : null} */}

                                                        {/* {type && type !== 'Cash' && type !== 'CPO' ? (
                                                            <>
                                                                <Form.Item
                                                                    name={'mode'}
                                                                    label="Mode"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Select Mode',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        allowClear
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        placeholder="Please select"
                                                                    >
                                                                        {modes.map((item) => {
                                                                            return (
                                                                                <Option value={item.ac_code} key={item.ac_code}>
                                                                                    {item.ac_desc} - {item.ac_mode}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )} */}
                                                        {type === 'Net Banking' ? (
                                                            <Form.Item
                                                                name={'utr'}
                                                                label="UTR Number"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter UTR Number',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        ) : null}
                                                        {type === 'Cheque' ? (
                                                            <>
                                                                <Form.Item
                                                                    name={'chequeNo'}
                                                                    label="Cheque Number"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Cheque Number',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'chequeDate'}
                                                                    label="Cheque Date"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Cheque Date',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker inputReadOnly />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}
                                                        {type === 'Credit Card' || type === 'Debit Card' ? (
                                                            <>
                                                                <Form.Item
                                                                    name={'cardNo'}
                                                                    label="Card Number(Last 4 digits)"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Card Number',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}
                                                        {type !== 'Cash' && type !== 'Credit Card' && type !== 'Debit Card' ? (
                                                            <>
                                                                <Form.Item
                                                                    name="attachments"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Share attachments of the receipt',
                                                                        },
                                                                    ]}
                                                                    label="Attach Payment Screenshot"
                                                                    valuePropName="fileList"
                                                                    extra="File size should not exceed 1 mb"
                                                                >
                                                                    <FileUpload
                                                                        callback={onUpload}
                                                                        onProgress={() => {
                                                                            setDisabled(true);
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}
                                                        <Form.Item
                                                            name={'remarks'}
                                                            label="Remarks"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter remarks',
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea rows={4} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                            </>
                                        )}

                                        <Button
                                            loading={loader}
                                            disabled={
                                                (department[0] === 'Department Handling Sales' && !selectedBooking) ||
                                                (disabled && department[0] === 'Department Handling Sales')
                                            }
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-button"
                                        >
                                            SUBMIT
                                        </Button>
                                    </Form>
                                </div>
                            </>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
