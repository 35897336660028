import React, { useContext } from 'react';

import { Tag, Row, Space } from 'antd';

import { Link } from 'react-router-dom';

import './request-info.scss';

import EmployeeDisplay from './../employee-display/employee-display';

import { GlobalContext } from '../../Store';

var images = {
    SELTOS: require('./../../assets/images/cars/seltos.png'),
    SONET: require('./../../assets/images/cars/sonet.png'),
    CARNIVAL: require('./../../assets/images/cars/carnival.png'),
    CARENS: require('./../../assets/images/cars/carens.png'),
    EV6:require('./../../assets/images/cars/ev6.png')
};

export default function RequestInfo({ request = {}, booking = {} }) {

    const { disableDisplayPhoneNumber } = useContext(GlobalContext);


    return (
        <div className="request-info vehicle-card left-detail">
            <div className="vehicle-image-wrapper">
                    <Tag color="purple">DMS Billing : {request.billing.status}</Tag>

                    {request.delivery && request.delivery.status !== 'pending' ? (
                        <>
                            <Tag color="purple">DMS Delivery Note : {request.delivery.delivered_note_at ? 'Delivery Note Taken' : 'pending'}</Tag>
                            <Tag color="purple">Delivery status : {request.delivery.status || 'pending'}</Tag>
                        </>
                    ) : null}

                <img src={images[request['model']]} alt="vehicle-img" />

                <p>{request.allocation && request.allocation.vinNo ? request.allocation.vinNo : null}</p>

                <h3>{request['variant']}</h3>

                <small>{request['color']}</small>
            </div>

            <div gutter={0} className="detail-wrapper">
                <div className="detail-element">
                    <Row span={12}>
                        <span>Customer</span>
                    </Row>
                    <Row span={12}>
                        <h3>{request.customer.customerName}</h3>
                    </Row>
                </div>

                {/*disable PhoneNumber for kec and tl */}
                {!disableDisplayPhoneNumber ?
                    <div className="detail-element">
                        <Row span={12}>
                            <span>Phone</span>
                        </Row>
                        <Row span={12}>
                            <h3>{request.customer.phone}</h3>
                        </Row>
                    </div>
                    : null}

                <div className="detail-element">
                    <Row span={12}>
                        <span>City</span>
                    </Row>
                    <Row span={12}>
                        <h3>{request['city']}</h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>KEC</Row>
                    <Row span={12}>
                        <h3>
                            <EmployeeDisplay employee_id={request.consultant_id} />  
                        </h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>Team Leader</Row>
                    <Row span={12}>
                        <h3>
                            <EmployeeDisplay employee_id={request.teamleader_id} />
                        </h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>Booking Date</Row>
                    <Row span={12}>
                        <h3 className="">{booking['Booking Date']}</h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>Created At</Row>
                    <Row span={12}>
                        <h3 className="">{request.created_at}</h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>Booking</Row>
                    <Row span={12}>
                        <h3 className="">
                            <Link to={`/bookings/${request.bookingNo}`}>{request.bookingNo}</Link>
                        </h3>
                    </Row>
                </div>

                <div className="detail-element">
                    <Row span={12}>View Request</Row>
                    <Row span={12}>
                        <h3 className="">
                            <Link to={`/requests/${request.id}`}>View Request</Link>
                        </h3>
                    </Row>
                </div>
            </div>
        </div>
    );
}
