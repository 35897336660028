/**
 * Booking Dashboard Component
 *
 * @description Component to show bookings status
 * @author Nihala Dilshi
 */

import React, { useState, useEffect } from 'react';

import { Table, Modal, Card } from 'antd';
import {dealerCodeKey} from '../../../global-config';

import { Bookings, Branches, StatusMasters } from '../../../../models';

var groupByNested = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        const keys = key.split('.');
        let value = x;
        for (let k of keys) {
            value = value[k];
            if (value === undefined) break;
        }
        const keyValue = value ? value : 'NoStatus';
        (rv[keyValue] = rv[keyValue] || []).push(x);
        return rv;
    }, {});
};


var groupBy = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


/**
 *
 * Component for  Booking
 *
 * @param {*} param0
 */
export default function BookingDashboard({ }) {
    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ bookings: [] });

    const [statusName, setStatusName] = useState([])

    const [dealerCode, setDealerCode] = useState([])


    useEffect(async() => {
       await getBookingStatus();
       await getBooking();
    }, []);

    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            }
        ];
        const result = await StatusMasters.get(queries);

        // fetching name field 
        result.status_masters.forEach((element) => {
            if(element.otherdetails.display != 'none'){
                statusName.push(element.name);
            }
        });
    }

   
    const generateColumns = (dealerCodeKey) => {
        // Initialize the columns array with the static 'Code' column
        const columns = [
            {
                title: 'Code',
                dataIndex: 'model',
            }
        ];
    
        // Iterate over the keys of the dealerKeys object to create dynamic columns
        for (const [key, value] of Object.entries(dealerCodeKey)) {
            // console.log("WWWWWWWWW",value)
            columns.push({
                title: key.replace('KL', ''), // Extracting the number part of the key for the title
                className: value, // Converting the name  for the className
                render: (entry) => entry[key].mtd
            });
        }
    
        // Add the static 'Total' column at the end
        columns.push({
            className: 'total',
            title: 'Total',
            render: (entry) => entry.all.mtd,
        });
    
        return columns;
    };
    
    // Generate the columns dynamically
    const columns = generateColumns(dealerCodeKey);

    /**
     *
     * Component for Booking
     *
     * @param {*} param0
     */

    async function getBooking() {

        var queries = [
            {
                field: 'actual_billing.status',
                value: 'pending',
            },
        ];

        Bookings.getAll(queries, 'bookings').then((result) => {
            const filteredBookings = result.all.filter((booking) => !booking.isCancelled && (booking && booking.current && booking.current.currentStatus));

            setContent({ bookings: filteredBookings });

            Branches.getAllBranches().then(async(res)=>{
                //  all dealerCode
                setDealerCode(res)

           await generateBookingDashboard(filteredBookings,res)
                .then((arr) => {
                    setSummary(arr);
                })
                .catch((error) => {
                    console.error('Error generating booking dashboard:', error);
                });

            })

        });
    }

    /**
     *
     * Component for generateBookingDashboard
     *
     * @param {*} param0
     */

    async function generateBookingDashboard(bookings,res) {
        var arr = [];

        // grouping booking have current status 
        const updatedData = bookings.map(obj => {
            obj.status = obj.current.currentStatus ? obj.current.currentStatus : 'NoStatus';
            return obj;
        });

        const status = groupByNested(updatedData, 'status');



        statusName.forEach(async(model) => {

            var towns = groupBy(status[model], 'Dealer Code');
            let town = {}

            await res.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
                
            });
         
            res.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                    };
                } else {
                    town[code] = { mtd: 0 };
                }
            });

            if (status[model]) {
                town.all = {
                    mtd: status[model].length,
                };
            }

            arr.push(town);
        });

        return arr;
    }

    return (
        <>
            <div>
                <>
                    {content['bookings'].length ? (
                        <Card title={`BOOKINGS`} style={{ width: '100%', textAlign: 'center' }}>
                            <Table
                                size="small"
                                scroll={{ x: true }}
                                rowKey={(record) => record.id}
                                dataSource={summary}
                                columns={columns}
                                pagination={false}
                                summary={(pageData) => {
                                    let summary = {};
                                    // Loop through each dealer code in the dealerCode array
                                    dealerCode.forEach((code) => {
                                        // Initialize each dealer code property in the summary object with default  mtd values
                                        summary[code] = {mtd: 0 };
                                    });
                                   

                                    pageData.forEach((entry) => {
                                        dealerCode.forEach((code) => {
                                            if (entry[code]) {
                                                summary[code].mtd += entry[code].mtd;
                                            }
                                        });
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Total</strong>
                                                </Table.Summary.Cell>

                                                {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].mtd}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            ></Table>

                        </Card>
                    ) : null}
                </>
            </div>
        </>
    );

}



