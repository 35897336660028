import React, { useState, useRef, useContext, useEffect, Fragment } from 'react';

import { Card, Typography, Table, Tabs, Button, Radio, DatePicker, Space, Menu, Dropdown } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { Cancellations, Bookings, Branches } from '../../../../models';

import { useReactToPrint } from 'react-to-print';

import moment from 'moment-timezone';

import { CSVLink } from 'react-csv';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import { ResponsiveLine } from '@nivo/line';

import './daily-overview.scss';
import { dealerCodeKey } from '../../../global-config';

const { Title } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

var groupBy = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
// set vehicle models
var vehicleModel = ['SELTOS', 'CARNIVAL', 'SONET', 'CARENS', 'EV6'];

export default function DailyOverview(props) {
    const print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const { user, branches } = useContext(GlobalContext);

    const [range, setRange] = useState(moment.tz('Asia/Calcutta').endOf('day'));

    const [option, setOption] = useState('transaction_date');

    const [dealerCode, setDealerCode] = useState(branches);

    function loadData(range) {
        setRange(range);
    }
    function updateTime(dt) {
        setRange(dt);

        loadData(dt);
    }
    useEffect(async () => {
        //    await getAllBranches();
    }, []);
    // all branches
    //    async function getAllBranches(){
    //         Branches.getAllBranches().then((res)=>{
    //             setDealerCode(res)
    //         })

    //     }
    return (
        <section className="daily-overview listing">
            <WrapperClass ref={print}>
                <div className="page-header">
                    <Title level={3}>Daily Overview</Title>

                    <div className="page-actions">
                        <Space>
                            <DatePicker inputReadOnly format={dateFormat} value={range} onChange={updateTime} allowClear={false} />

                            <Button
                                size="small"
                                onClick={() => {
                                    let r = range;

                                    setRange(null);
                                    // getAllBranches()

                                    setTimeout(() => {
                                        loadData(r);
                                    });
                                }}
                            >
                                Refresh
                            </Button>

                            <Button size="small" type="primary" className="print-button" onClick={handlePrint}>
                                Download
                            </Button>

                            {/* { range && <ExportXLS csvData={range} />}  */}
                            {/* <CSVLink data={finResult}>Download me</CSVLink> */}
                        </Space>
                    </div>
                </div>

                <Tabs>
                    <TabPane tab="DMS" key="0">
                        <DailyDashboard option={option} range={range} url="all" app={'dms'} dealerCode={branches} />
                    </TabPane>
                    <TabPane tab="Actual" key="1">
                        <DailyDashboard option={option} range={range} url="actual" app={'actual'} dealerCode={branches} />
                    </TabPane>
                </Tabs>
            </WrapperClass>
        </section>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

var enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    dates.push(currDate.clone().valueOf());

    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        // console.log(currDate.toDate());
        dates.push(currDate.clone().valueOf());
    }

    return dates;
};

/**
 *
 * Get the graph data
 *
 * @param {*} result
 */
function getGraphData(result, range) {
    var content = {};

    var start = range[0];

    var end = range[1];

    var modelWise = groupBy(result, 'Model');

    var models = Object.keys(modelWise);

    ['SELTOS', 'SONET', 'CARNIVAL', 'CARENS', 'EV6'].forEach((model) => {
        var data = modelWise[model] || [];

        content[model] = [];

        var cityWise = groupBy(data, 'Dealer Code');

        var locations = Object.keys(cityWise);

        locations.forEach((location) => {
            var bookings = cityWise[location];

            var modelWise = groupBy(bookings, 'MODEL');

            var models = Object.keys(modelWise);

            console.log(models);

            var dateWise = groupBy(bookings, 'bookingDate');

            console.log(dateWise);

            var dates = Object.keys(dateWise);

            var range = enumerateDaysBetweenDates(start, end);

            content[model].push({
                id: location,
                data: range.map((date) => {
                    return {
                        x: moment(parseInt(date)).format('MMM DD'),
                        y: dateWise[date] ? dateWise[date].length : 0,
                    };
                }),
            });
        });
    });

    return content;
}

var exportData = {};

var graphData;

/**
 * Wrapper Component for the daily report
 *
 * @author Soxo
 *
 * @param {*} param0
 * @returns
 */
function DailyDashboard({ url, range, option, app, dealerCode }) {
    const [finResult, setFinResult] = useState([]);

    const [bookingStatus, setBookingStatus] = useState('All Bookings');

    const [preBooking, setPreBooking] = useState(false);

    var status = ['All Bookings', 'Pending Bookings', 'Paid Bookings'];

    const menus = status.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleClick}>{menus}</Menu>;

    function handleClick(e) {
        setBookingStatus(e.key);

        if (e.key === 'Pre Booking') {
            setPreBooking(true);
        } else {
            setPreBooking(false);
        }
    }

    var headers = [
        { label: 'First Name', key: 'firstname' },
        { label: 'Last Name', key: 'lastname' },
        { label: 'Email', key: 'email' },
    ];

    /**
     * Prepare the data
     *
     * @param {*} model
     * @param {*} entry
     */
    

    return (
        <Fragment>
            {app === 'dms' ? (
                <Fragment>
                    {/* Bookings Dropdown */}

                    <div className="page-header">
                        <div className="page-actions">
                            <Dropdown overlay={menu}>
                                <Button size={'small'}>
                                    {bookingStatus} <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>

                    {/* Bookings Dropdown Ends */}

                    <Card title={`Bookings`} size="small">
                        <Booking
                            status={bookingStatus}
                            url={url}
                            option={option}
                            date={range}
                            callback={(result) => { }}
                            preBooking={preBooking}
                            dealerCode={dealerCode}
                        />
                    </Card>

                    <Card title={`Delivery`} size="small">
                        <Delivery
                            url={url}
                            option={option}
                            date={range}
                            callback={(result) => {
                                // setContent({
                                //     ...content,
                                //     delivery: result
                                // })
                                // prepareData('delivery', result);
                            }}
                            dealerCode={dealerCode}
                        />
                    </Card>

                    <Card title={`Retails`} size="small">
                        <Retails
                            url={url}
                            option={option}
                            date={range}
                            callback={(result) => {
                                // setContent({
                                //     ...content,
                                //     retails: result
                                // })
                                // prepareData('retails', result);
                            }}
                            dealerCode={dealerCode}
                        />
                    </Card>

                    <Card title={`Cancellation`} size="small">
                        <Cancellation url={url} option={option} date={range} callback={(result) => { }} dealerCode={dealerCode} />
                    </Card>

                    <Card title={`Balance Bookings`} size="small">
                        <BalanceBooking
                            url={url}
                            ftd={false}
                            callback={(result) => { }}
                            status={bookingStatus}
                            preBooking={preBooking}
                            dealerCode={dealerCode}
                        />
                    </Card>
                </Fragment>
            ) : (
                <Fragment>
                    <Card title={`Actual Retails`} size="small">
                        <ActualRetails
                            url={url}
                            option={option}
                            date={range}
                            callback={(result) => {
                                // setContent({
                                //     ...content,
                                //     retails: result
                                // })
                                // prepareData('retails', result);
                            }}
                            dealerCode={dealerCode}
                        />
                    </Card>

                    <Card title={`Actual Delivery`} size="small">
                        <ActualDelivery
                            url={url}
                            option={option}
                            date={range}
                            callback={(result) => {
                                // setContent({
                                //     ...content,
                                //     delivery: result
                                // })
                                // prepareData('delivery', result);
                            }}
                            dealerCode={dealerCode}
                        />
                    </Card>
                </Fragment>
            )}
        </Fragment>
    );
}


const generateColumns = (dealerCodeKey) => {
    // Initialize the columns array with the static 'Model' column
    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
        },
    ];

    // Iterate over the keys of the dealerCodeKey object to create dynamic columns
    for (const [key, value] of Object.entries(dealerCodeKey)) {
        columns.push({
            title: value.charAt(0).toUpperCase() + value.slice(1), // Capitalize the first letter for the title
            className: value, // Use the value in lowercase for the className
            dataIndex: key,
            children: [
                {
                    className: value,
                    title: 'FTD',
                    render: (entry) => entry[key]?.ftd, // Safely access ftd property
                },
                {
                    className: value,
                    title: 'MTD',
                    render: (entry) => entry[key]?.mtd, // Safely access mtd property
                },
            ],
        });
    }

    // Add the static 'Total' column at the end
    columns.push({
        className: 'total',
        title: 'Total',
        children: [
            {
                className: 'total',
                title: 'FTD',
                render: (entry) => entry.all?.ftd, // Safely access ftd property
            },
            {
                className: 'total',
                title: 'MTD',
                render: (entry) => entry.all?.mtd, // Safely access mtd property
            },
        ],
    });

    return columns;
};

// Generate the columns dynamically
const columns = generateColumns(dealerCodeKey);

// Generate the columns dynamically
const generateBookingBalanceColumns = (dealerCodeKey) => {
    // Initialize the columns array with the static 'Model' column
    const balanceBookingColumn = [
        {
            title: 'Model',
            dataIndex: 'model',
        }
    ];

    // Iterate over the keys of the dealerCodeKey object to create dynamic columns
    for (const [key, value] of Object.entries(dealerCodeKey)) {
        balanceBookingColumn.push({
            title: value.charAt(0).toUpperCase() + value.slice(1), // Capitalize the first letter for the title
            className: value, // Use the value in lowercase for the className
            render: (entry) => entry[key]?.mtd, // Safely access mtd property
        });
    }

    // Add the static 'Total' column at the end
    balanceBookingColumn.push({
        className: 'total',
        title: 'Total',
        render: (entry) => entry.all?.mtd, // Safely access mtd property
    });

    return balanceBookingColumn;
};

// Generate the columns dynamically
const bookingBalanceColumns = generateBookingBalanceColumns(dealerCodeKey);


/**
 *
 * Component for Booking
 *
 * @param {*} param0
 */
function Booking({ url, date, callback, preBooking, status, dealerCode }) {
    const [summary, setSummary] = useState([]);

    const [bookings, setBookings] = useState({ bookings: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'bookingDate',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'bookingDate',
                operator: '<=',
                value: moment(range).valueOf(),
            },
        ];

        // According to Booking Status we load the bookings
        if (status !== 'All Bookings') {
            if (status === 'Pending Bookings') {
                queries.push({ field: 'booking_status', value: 'prebooking' });
            } else {
                queries.push({ field: 'booking_status', value: 'booking' });
            }
        }

        FirebaseUtils.getAllListing(queries, 'bookings').then(async (result) => {
            console.log(result);

            setBookings({ bookings: result.all.filter((booking) => !booking.isCancelled) });

            var arr = [];
            arr = await generateBookingReport(
                result.all.filter((booking) => !booking.isCancelled),
                moment(range).startOf('day').valueOf(),
                dealerCode
            );
            if (arr && arr.length > 0) {
                setSummary(arr);

                setLoader(false);

                callback(arr);
            }
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'all') {
                getAllDMSBookings(date);
            }
        }
    }, [date, status]);

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {bookings.timestamp}</p> : null}
                    <div>{/* {csv && <ExportXLS csvData={csv} />} */}</div>
                    <div>{/* <CSVLink data={finResult}>Download me</CSVLink> */}</div>

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {bookings['bookings'].length ? (
                        <Table
                            bordered
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });

                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}
////

/**
 *
 * @param {*} bookings
 */
async function generateBookingReport(bookings, today, dealerCode) {
    var arr = [];
    if (bookings.length && dealerCode.length > 0) {
        var models = groupBy(bookings, 'Model');

        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'Dealer Code');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                        ftd: customers.filter((booking) => booking.bookingDate === today).length,
                    };
                } else {
                    town[code] = { ftd: 0, mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                    ftd: models[model].filter((booking) => booking.bookingDate === today).length,
                };
            }

            arr.push(town);
        });

        return arr;
    }
}

/**
 *
 * @param {*} Cancellations
 */
function generateCancellationReport(cancellations, today, dealerCode) {
    var arr = [];

    var bookingsArr = [];

    return Promise.all(
        cancellations.map((item) => {
            var query = [
                {
                    field: 'Booking No',
                    value: item.bookingNo,
                },
            ];
            return Bookings.get(query).then((res) => {
                bookingsArr.push({
                    ...item,
                    ...res.bookings[0],
                });

                return bookingsArr;
            });
        })
    ).then((bookingsArr) => {
        var result = bookingsArr[0];

        if (result) {
            var models = groupBy(result, 'Model');

            // Models
            vehicleModel.forEach(async (model) => {
                var towns = groupBy(models[model], 'Dealer Code');
                let town = {};
                await dealerCode.forEach((code) => {
                    // Initialize each dealer code property in the town object with default ftd and mtd values
                    // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                    town[code] = { ftd: 0, mtd: 0 };
                    // The 'model' property indicates the car model associated with the town
                    town['model'] = model;
                });

                dealerCode.forEach((code) => {
                    var customers = towns[code];

                    if (customers && customers.length) {
                        town[code] = {
                            mtd: customers.length,
                            ftd: customers.filter((cancellation) => cancellation.dms_cancellation_date === today).length,
                        };
                    } else {
                        town[code] = { ftd: 0, mtd: 0 };
                    }
                });

                if (models[model]) {
                    town.all = {
                        mtd: models[model].length,
                        ftd: models[model].filter((cancellation) => cancellation.dms_cancellation_date === today).length,
                    };
                }

                arr.push(town);
            });
            return arr;
        }
    });
}

/**
 *
 * @param {*} bookings
 */
function generateRetailReport(bookings, today, dealerCode) {
    var arr = [];

    if (bookings.length) {
        var models = groupBy(bookings, 'Model');

        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'Dealer Code');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                        ftd: customers.filter((booking) => booking.confirm_date === today).length,
                    };
                } else {
                    town[code] = { ftd: 0, mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                    ftd: models[model].filter((booking) => booking.confirm_date === today).length,
                };
            }

            arr.push(town);
        });
    }

    return arr;
}
/**
 *
 * @param {*} bookings
 */
function generateActualRetailReport(bookings, today, dealerCode) {
    var arr = [];

    if (bookings.length) {
        var models = groupBy(bookings, 'Model');

        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'dealerCode');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                        ftd: customers.filter((bill) => {
                            let date = moment(bill.invoice_date_millisecond).startOf('day').valueOf();

                            return date === today;
                        }).length,
                    };
                } else {
                    town[code] = { ftd: 0, mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                    ftd: models[model].filter((bill) => {
                        let date = moment(bill.invoice_date_millisecond).startOf('day').valueOf();

                        return date === today;
                    }).length,
                };
            }

            arr.push(town);
        });
    }

    return arr;
}

/**
 *
 * @param {*} bookings
 */
function generateDeliveryReport(bookings, today, dealerCode) {
    var arr = [];

    // Bookings length
    if (bookings.length && dealerCode.length > 0) {
        // var today = moment().startOf('day').valueOf();

        // Models
        var models = groupBy(bookings, 'Model');

        // Models
        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'Dealer Code');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                        ftd: customers.filter((booking) => booking.delivery_date === today).length,
                    };
                } else {
                    town[code] = { ftd: 0, mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                    ftd: models[model].filter((booking) => booking.delivery_date === today).length,
                };
            }

            arr.push(town);
        });
        return arr;
    }
}
///

/**
 *
 * @param {*} bookings
 */
function generateActualDeliveryReport(bookings, today, dealerCode) {
    var arr = [];

    // Bookings length
    if (bookings.length) {
        // var today = moment().startOf('day').valueOf();

        // Models
        var models = groupBy(bookings, 'model');

        // Models
        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'dealerCode');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                        ftd: customers.filter((booking) => booking.actual_delivery && booking.actual_delivery.actual_delivery_date === today).length,
                    };
                } else {
                    town[code] = { ftd: 0, mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                    ftd: models[model].filter((booking) => booking.actual_delivery && booking.actual_delivery.actual_delivery_date === today).length,
                };
            }

            arr.push(town);
        });
    }

    return arr;
}

/**
 *
 * Component for Booking
 *
 * @param {*} param0
 */
function Retails({ url, date, callback, dealerCode }) {
    var graphData;

    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ sales: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'confirm_date',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'confirm_date',
                operator: '<=',
                value: moment(range).valueOf(),
            },
        ];

        FirebaseUtils.getAllListing(queries, 'sales').then(async (result) => {
            console.log(result);

            setContent({ sales: result.all.filter((sales) => !sales.isCancelled) });

            // graphData = getGraphData(result.all, date);

            var arr = await generateRetailReport(
                result.all.filter((sales) => !sales.isCancelled),
                moment(range[1]).startOf('day').valueOf(),
                dealerCode
            );

            console.log(arr);
            if (arr && arr.length > 0) {
                setSummary(arr);

                setLoader(false);

                callback(arr);
            }
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'all') {
                getAllDMSBookings(date);
            }
        }
    }, [date]);

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {content['sales'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });
                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}
/**
 *
 * Component for Booking
 *
 * @param {*} param0
 */
function ActualRetails({ url, date, callback, dealerCode }) {
    var graphData;

    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ sales: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'invoice_date_millisecond',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'invoice_date_millisecond',
                operator: '<=',
                value: moment(range).valueOf(),
            },
            {
                field: 'type',
                value: 'basic',
            },
            {
                field: 'cancellation_status',
                value: false,
            },
        ];

        FirebaseUtils.getAllListing(queries, 'bills').then(async (result) => {
            console.log(result.all);

            setContent({ sales: result.all });

            // graphData = getGraphData(result.all, date);

            result.all = result.all.map((item) => {
                return {
                    ...item,
                    Model: item.attributes && item.attributes.model,
                };
            });

            var arr = await generateActualRetailReport(result.all, moment(range).startOf('day').valueOf(), dealerCode);

            setSummary(arr);

            setLoader(false);

            callback(arr);
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'actual') {
                getAllDMSBookings(date);
            }
        }
    }, [date]);

    return (
        <>
            <div>
            </div>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {content['sales'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });

                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 * Component for Booking
 *
 * @param {*} param0
 */
function Delivery({ url, date, callback, dealerCode }) {
    var graphData;

    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ sales: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'delivery_date',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'delivery_date',
                operator: '<=',
                value: moment(range).valueOf(),
            },
        ];

        FirebaseUtils.getAllListing(queries, 'sales').then(async (result) => {
            setContent({ sales: result.all.filter((sales) => !sales.isCancelled) });

            var arr = [];
            arr = await generateDeliveryReport(
                result.all.filter((sales) => !sales.isCancelled),
                moment(range).startOf('day').valueOf(),
                dealerCode
            );
            if (arr && arr.length > 0) {
                setSummary(arr);

                setLoader(false);

                callback(arr);
                console.log(arr);
            }
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'all') {
                getAllDMSBookings(date);
            }
        }
    }, [date]);

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {content['sales'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });

                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 * Component for Balance Booking
 *
 * @param {*} param0
 */
function BalanceBooking({ url, callback, ftd, status, dealerCode }) {
    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ bookings: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings() {
        setLoader(true);

        var queries = [
            {
                field: 'billing.status',
                value: 'pending',
            },
        ];

        // According to Booking Status we load the bookings
        if (status !== 'All Bookings') {
            if (status === 'Pending Bookings') {
                queries.push({ field: 'booking_status', value: 'prebooking' });
            } else {
                queries.push({ field: 'booking_status', value: 'booking' });
            }
        }

        // if (preBooking) {
        //     queries.push({ field: 'booking_status', value: 'prebooking' });
        // } else {
        //     queries.push({ field: 'booking_status', value: 'booking' });
        // }

        Bookings.getAll(queries, 'bookings').then(async (result) => {
            //   FirebaseUtils.getAllListing(queries, 'bookings').then((result) => {
            console.log(result);

            setContent({ bookings: result.all.filter((booking) => !booking.isCancelled) });

            // graphData = getGraphData(result.all, date);

            var arr = await generateBalanceBookingReport(
                result.all.filter((booking) => !booking.isCancelled),
                dealerCode
            );

            setSummary(arr);

            setLoader(false);

            callback(arr);
        });
    }
    useEffect(() => {
        if (url === 'all') {
            getAllDMSBookings();
        }
    }, []);

    useEffect(() => {
        if (url === 'all') {
            getAllDMSBookings();
        }
    }, [status]);

    return (
        <>
            <div>
            </div>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {content['bookings'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={bookingBalanceColumns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { mtd: 0 };
                                });
                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            // summary[code] += entry[code];

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        {/* <Table.Summary.Cell>
                                                            <strong>{summary[code]}</strong>
                                                        </Table.Summary.Cell> */}
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 * Component for generateBalanceBookingReport
 *
 * @param {*} param0
 */

function generateBalanceBookingReport(bookings, dealerCode) {
    var arr = [];

    // Bookings length
    if (bookings.length) {
        // var today = moment().startOf('day').valueOf();

        // Models
        var models = groupBy(bookings, 'Model');

        // Models
        vehicleModel.forEach(async (model) => {
            var towns = groupBy(models[model], 'Dealer Code');
            let town = {};
            await dealerCode.forEach((code) => {
                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { ftd: 0, mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;
            });

            dealerCode.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                    };
                } else {
                    town[code] = { mtd: 0 };
                }
            });

            if (models[model]) {
                town.all = {
                    mtd: models[model].length,
                };
            }

            arr.push(town);
        });
    }

    return arr;
}

/**
 *
 * Component for Cancellation
 *
 * @param {*} param0
 */

function Cancellation({ url, date, callback, dealerCode }) {
    const [summary, setSummary] = useState([]);

    const [updated, setUpdated] = useState('');

    const [content, setContent] = useState({ cancellations: [] });

    const [loader, setLoader] = useState(true);

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'dms_cancellation_date',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'dms_cancellation_date',
                operator: '<=',
                value: moment(range).valueOf(),
            },
        ];

        Cancellations.getAll(queries, 'cancellations').then(async (result) => {
            console.log(result.all);

            setContent({ cancellations: result.all.filter((cancellation) => !cancellation.isCancelled) });

            await generateCancellationReport(
                result.all.filter((cancellation) => !cancellation.isCancelled),

                moment(range).startOf('day').valueOf(),
                dealerCode
            ).then((res) => {
                setSummary(res);

                setLoader(false);

                callback(res);
            });
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'all') {
                getAllDMSBookings(date);
            }
        }
    }, [date]);
    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                            <DailyGraph data={graphData} />
                        </div> */}

                    {content['cancellations'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });

                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

/**
 *
 * Component for Booking
 *
 * @param {*} param0
 */
function ActualDelivery({ url, date, callback, dealerCode }) {
    var graphData;

    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ sales: [] });

    const [loader, setLoader] = useState(true);

    const [updated, setUpdated] = useState('');

    function getAllDMSBookings(range) {
        setLoader(true);

        var queries = [
            {
                field: 'actual_delivery.actual_delivery_date',
                operator: '>=',
                value: moment(range).startOf('month').valueOf(),
            },

            {
                field: 'actual_delivery.actual_delivery_date',
                operator: '<=',
                value: moment(range).valueOf(),
            },
            {
                field: 'actual_delivery.status',
                value: 'Delivery Completed',
            },
        ];

        FirebaseUtils.getAllListing(queries, 'requests').then(async (result) => {
            //console.log(result);

            setContent({ sales: result.all });

            // graphData = getGraphData(result.all, date);

            var arr = await generateActualDeliveryReport(result.all, moment(range).startOf('day').valueOf(), dealerCode);

            setSummary(arr);

            setLoader(false);

            callback(arr);
            // console.log(arr);
        });
    }

    useEffect(() => {
        if (date) {
            if (url === 'actual') {
                getAllDMSBookings(date);
            }
        }
    }, [date]);

    return (
        <>
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {updated ? <p style={{ float: 'right' }}>Last Updated {content.timestamp}</p> : null}

                    {/* <div className="graph-container">
                        <DailyGraph data={graphData} />
                    </div> */}

                    {content['sales'].length ? (
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => record.id}
                            dataSource={summary}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                let summary = {};
                                // Loop through each dealer code in the dealerCode array
                                dealerCode.forEach((code) => {
                                    // Initialize each dealer code property in the summary object with default ftd and mtd values
                                    summary[code] = { ftd: 0, mtd: 0 };
                                });

                                pageData.forEach((entry) => {
                                    dealerCode.forEach((code) => {
                                        if (entry[code]) {
                                            summary[code].ftd += entry[code].ftd;

                                            summary[code].mtd += entry[code].mtd;
                                        }
                                    });
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>

                                            {dealerCode.map((code) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].ftd}</strong>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <strong>{summary[code].mtd}</strong>
                                                        </Table.Summary.Cell>
                                                    </>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    ) : null}
                </>
            )}
        </>
    );
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const DailyGraph = ({ data /* see data tab */ }) => {
    const [mode, setMode] = useState('SELTOS');

    return (
        <>
            <Radio.Group
                size={'small'}
                value={mode}
                onChange={(event) => {
                    setMode(event.target.value);
                }}
            >
                <Radio.Button value="SELTOS">Seltos</Radio.Button>
                <Radio.Button value="SONET">Sonet</Radio.Button>
                <Radio.Button value="CARNIVAL">Carnival</Radio.Button>
                <Radio.Button value="EV6">EV6</Radio.Button>
            </Radio.Group>

            <ResponsiveLine
                data={data[mode]}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Dates',
                    legendOffset: 40,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};
