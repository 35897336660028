import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Skeleton, InputNumber, DatePicker, Select } from 'antd';

import SELECT from 'react-select';

import { GlobalContext } from '../../../../Store';

import moment from 'moment'
// import { Switch } from './../../../../elements/'

// import { Pages, Models, Menus } from './../../../../models';

import { Location } from '../../../../utils/location.utils';
import { Users, Designations, Employees } from '../../../../models';
import { cityKeys } from '../../../global-config';
// import {JSONInput} from '@soxo/firebase-core'



const { Option } = Select;

export default function MenuAdd({ model, edit, history, match, setVisible, formContent, callback, refresh, additional_queries, ...props }) {

    let step = Location.search().step || 1;

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    const [users, setUsers] = useState([]);

    const [designation, setDesignation] = useState([]);


    const [headers, setHeaders] = useState([]);

    const [head, setHead] = useState([])

    const [models, setModels] = useState([]);

    // const [modules, setModules] = useState([]);

    const [body, setBody] = useState();

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { TextArea } = Input;

// Convert cityKey object to array of objects
    var locations = Object.keys(cityKeys);

    useEffect(() => {

        getHeaders()

        getUsers()

        getDesignations()

        // getInitialValues()

        // setLoading(false)
    }, []);


    /**
     * 
     * Get current records in employee to list as header_id
     */
     function getHeaders() {
        var queries = []
        Employees.get(queries).then((result) => {

            setHead(result.employees)

            getInitialValues(result.employees)
            // setUsers(result.users)
            let b = Object.keys(result.employees).map((employee) => {

                return { label: result.employees[employee].fname + ' ' + result.employees[employee].lname, value: result.employees[employee].id };
            });
            console.log(b)
            setHeaders(b);

        })
    }

    //Get Users from user Table
    function getUsers() {
        var queries = []
        Users.get(queries).then((result) => {
            // setUsers(result.users)
            let b = Object.keys(result.users).map((request) => {
   
                return { label: result.users[request].name, value: result.users[request].id };
            });
            console.log(b)
            setUsers(b);

        })
    }


    /**
     * 
     * @returns 
     * Get data ready to initialize in the form. joining date and resigntaion dates are stored as timestamp in database
     * we have change those to moment values for datepicker
     * Convert userid to object to prepopulate it to the select component
     */
    function getInitialValues(header) {

        if (formContent.header_id)

            var headEmployee = header.find(ele => ele.id === formContent.header_id)

        if (headEmployee)
            formContent = {
                ...formContent,
                header_id: {
                    label: headEmployee.fname + ' ' + headEmployee.lname,
                    value: headEmployee.id
                }
            }

        if (formContent.joining_date || formContent.resignation_date) {

            var date = formContent.joining_date.toDate()
            var d = date.valueOf()

            if (formContent.resignation_date) {

                var resigndate = formContent.resignation_date.toDate()
                var value = resigndate.valueOf()

                formContent = {
                    ...formContent,
                    resignation_date: moment(value)
                }
            }

            formContent = {
                ...formContent,
                joining_date: moment(d)

            }
            setBody(formContent)
            setLoading(false)
            return formContent
        }
        else {
            setBody(formContent)
            setLoading(false)
            return formContent
        }

    }


//Designations from designation table
    function getDesignations() {
        var queries = []
        Designations.get(queries).then((result) => {
            console.log(result)
            setDesignation(result.designations)
        })
    }

    /**
     * On submit.This is used for both adding and editing of employees
     * joining and resigntaion dates are converted to timestamp format
     * If formContent.id is present the record with corresponding id is updated using the new values
     * Or a new record is created for the employee
     * @param {*} values 
     */

    const onSubmit = (values) => {

        setLoading(true);

        if (values.dms_name) {
            values.dms_name = values.dms_name.trim()
        }

        var date = values.joining_date
        if (date) {
            values = {
                ...values,
                joining_date: new Date(date)

            }
        }

        if (values.resignation_date) {
            values = {
                ...values,
                resignation_date: new Date(values.resignation_date)

            }
        }
        else {
            values = {
                ...values,
                resignation_date: null
            }
        }

        if (values.header_id) {
            values = {
                ...values,
                header_id: values.header_id.value
            }
        }

        if (!values.remarks) {
            values = {
                ...values,
                remarks: null
            }
        }
        //When header_id is edited, step from the selected header_id is taken and one is added to get the step for the edited record
        //By this way the edited employee will be under the selected header_id
        var headEmployee = head.find(ele => ele.id === values.header_id)

        if (formContent.id)
            var employeeUnder = head.filter(ele => ele.header_id === formContent.id)

        var config = {
            hideAlert: true
        }

        //The step for  employees under the edited employee should also be changed correspondingly
        if (employeeUnder) {

            employeeUnder.map(async (ele) => {
                await Employees.update(ele.id, {
                    step: headEmployee.step + 2
                }, config)
            })
        }

        if (headEmployee) {
            values = {
                ...values,
                step: headEmployee.step + 1
            }
        }

        if (formContent.id) {

            console.log(values)


            // Update of model
            model.update(formContent.id, values, user).then(() => {

                callback();

                setLoading(false);
                refresh()

            });

        }

        else {




            values['header_id'] = null;

            values.step = parseInt(step);

            // Append the additional queries to the object
            additional_queries.forEach(({ field, value }) => {

                values[field] = value;

            })

            const employeeRef = Employees.getRecordReference()


            Employees.addDocument(employeeRef.id, values).then((result) => {

                if (values.userid) {
                    var param = {
                        employee_id: employeeRef.id
                    }
                    Users.update(values.userid.value, { 'employee_id': employeeRef.id }).then((result) => {
                        console.log(result)
                    })
                }

                console.log(result)
                setVisible(false)
                setLoading(false)
                refresh()

            })
        }

    };



    return (
        <section className="collection-add">
            {/* <Title level={4}>{edit ? 'Edit' : 'Add'} Menu</Title> */}

            {loading ? (
                <Skeleton />
            ) : (
                <Form initialValues={{
                    ...body
                }} form={form}
                    layout="vertical" onFinish={onSubmit}>


                    <Form.Item
                        name='fname'
                        label="First Name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name='lname'
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User ID" 
                        name="userid"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <SELECT style={{ width: '100%' }} options={users} isClearable={true}/>
                    </Form.Item>


                    <Form.Item label="Address" name="address">
                        <Input />
                    </Form.Item>



                    <Form.Item label="Phone Number" name="phone">
                        <Input />
                    </Form.Item>


                    <Form.Item label="Joining Date" name="joining_date" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <DatePicker />
                    </Form.Item>

                    <Form.Item label="Header ID" name="header_id">
                        <SELECT style={{ width: '100%' }} options={headers} isClearable={true} />
                    </Form.Item>

                    <Form.Item label="Resignation Date" name="resignation_date" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <DatePicker />
                    </Form.Item>



                    <Form.Item label="Designation" name="designation" rules={[
                        {
                            required: false,
                        },]}>
                        <Select style={{ width: '100%' }} allowClear={true}>
                            {designation.map((model, key) => (
                                <Option key={key} value={model.id}>{model.designation}</Option>
                            ))}
                        </Select>
                    </Form.Item>



                    <Form.Item label="Branch" name="branch" rules={[
                        {
                            required: true,
                        },]}>
                        <Select style={{ width: '100%' }} allowClear={true}>
                            {locations.map((model, key) => (
                                <Option key={key} value={model}>{model}</Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item label="Basic Pay" name="basic_pay">
                        <Input />
                    </Form.Item>


                    <Form.Item label="DMS Name" name="dms_name" rules={[
                        {
                            required: true,
                        },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Remarks" name="remarks" rules={[
                        {
                            required: false,
                        },]}>
                        <TextArea />
                    </Form.Item>


                    {/* <Form.Item name={"attributes"} label="Attributes">

                        <JSONInput />

                    </Form.Item> */}

                    <Form.Item>
                        <Button loading={loading} htmlType={'submit'} type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </section>

    );
}
