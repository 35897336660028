import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';

import { Progress, Button, Table, Typography } from 'antd';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import Rupee from '../../../../components/rupee/rupee';

// import { ExportReactCSV } from "./ExportReactCSV";

import { ExportReactCSV } from '../../resources/generic/generic-list/ExportReactCSV';
import { cityKeys } from '../../../global-config';
import './if-status.scss';

import { CheckCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const columns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => index + 1,
        // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    // {
    //     title: 'Kozhikode',
    //     // dataIndex: 'kozhikode',
    //     render: (value) => {
    //         return <Rupee value={value.kozhikode}></Rupee>
    //     }
    // },
    // {
    //     title: 'Kannur',
    //     render: (value) => {
    //         return <Rupee value={value.kannur}></Rupee>
    //     }
    // },
    // {
    //     title: 'Malappuram',
    //     render: (value) => {
    //         return <Rupee value={value.malappuram}></Rupee>
    //     }
    // },
    // {
    //     title: 'Total',
    //     render: (value) => {
    //         return <Rupee value={value.total}></Rupee>
    //     }
    // },

    {
        title: 'Kozhikode',
        // dataIndex: 'kozhikode',
        render: (value) => {
            return value.kozhikode;
        },
    },

    {
        title: 'Kannur',
        render: (value) => {
            return value.kannur;
        },
    },

    {
        title: 'Malappuram',
        render: (value) => {
            return value.malappuram;
        },
    },

    {
        title: 'Kondotty',
        render: (value) => {
            return value.kondotty;
        },
    },
    {
        title: 'Tirur',
        render: (value) => {
            return value.tirur;
        },
    },
    {
        title: 'Vadakara',
        render: (value) => {
            return value.vadakara;
        },
    },
    {
        title: 'Kasargod',
        render: (value) => {
            return value.kasargod;
        },
    },

    {
        title: 'Total',
        render: (value) => {
            return value.total;
        },
    },
];

export default function IFStatus() {
    let [current, setCurrent] = useState('');

    const [breakup, setBreakup] = useState([]);

    const [data, setData] = useState({});

    const [expRecord, setExptRecords] = useState();

    const [record, setRecord] = useState({ report: {} });

    var cityArray  = Object.keys(cityKeys);

    let [summary, setSummary] = useState({
        values: {
            approved: 0,
            advance: 0,
            utilised: 0,
            available: 0,
        },
        total: 0,
        vehicles: {},
        vehicleCount: 0,
    });

    useEffect(() => {
        getStatus();
    }, []);

    function getStatus() {
        FirebaseUtils.getStatus().then((data) => {
            console.log(data);

            if (Object.keys(data).length) {
                // Get the keys of the data
                let keys = Object.keys(data);

                // Consider count as current key
                let current = keys.length - 1;

                // get the ids of the data
                var ids = Object.keys(data);

                // Set the current record as last one
                var record = data[ids[current]];

                setCurrent(current);

                setRecord(record);

                setData(data);

                updateSummary(record);
            }
        });
    }

    function getExportData(data, columns) {
        let approved = data[0];

        let utilised = data[1];

        let fundGap = data[6];

        var fundGapPercent = {};

        let fundUtilisedPercent = {};

        cityArray.forEach((key) => {
            fundGapPercent[key] = (fundGap[key] / approved[key]) * 100;

            fundUtilisedPercent[key] = (utilised[key] / approved[key]) * 100;
        });

        return data.map((record, index) => {
            var content = {
                '#': index + 1,
            };

            columns.forEach((column) => {
                if (column.title === '#') {
                } else if (column.title === 'Description') {
                    return (content[column.title] = record[column.dataIndex]), 'Fund Gap', 'Fund-Utilised';
                } else {
                    return (
                        (content[column.title] = record[column.title.toLowerCase()]),
                        fundGapPercent[column.title.toLowerCase()],
                        fundUtilisedPercent[column.title.toLowerCase()]
                    );
                }
            });
            console.log(content);
            return content;
        });
    }

    function updateSummary(record) {
        var arr = [];

        var headers = [
            {
                caption: 'OD Approved (Rs)',
                field: 'approved',
                value: (content) => content.values.approved,
            },
            {
                caption: 'OD Utilised (Rs)',
                value: (content) => content.values.utilised,
            },

            {
                caption: 'OD Available (Rs)',
                value: (content) => {
                    // In some cases approved is string
                    // In such case remove comma from string and convert to number
                    var approved;
                    if (typeof content.values.approved === 'string') {
                        approved = parseFloat(content.values.approved.replace(/,/g, ''));
                    } else {
                        approved = content.values.approved;
                    }

                    // In some cases utilised is string
                    // In such case remove comma from string and convert to number
                    var utilised;
                    if (typeof content.values.utilised === 'string') {
                        utilised = parseFloat(content.values.utilised.replace(/,/g, ''));
                    } else {
                        utilised = content.values.utilised;
                    }

                    const available = (approved - utilised).toFixed(2);
                    return available;
                },
            },

            {
                caption: 'Stock (Rs)',
                value: (content) => content.total.toFixed(2),
            },
            {
                caption: 'Count',
                value: (content) => `${content.vehicleCount}`,
            },

            {
                caption: 'Advance (Rs)',
                value: (content) => content.values.advance,
            },

            {
                caption: 'Total Stock (Rs)',
                value: (content) => {
                    // In some cases advance is string
                    // In such case remove comma from string and convert to number
                    var advance;
                    if (typeof content.values.advance === 'string') {
                        advance = parseFloat(content.values.advance.replace(/,/g, ''));
                    } else {
                        advance = content.values.advance;
                    }
                    var totalStock = advance + parseFloat(content.total);
                    return totalStock.toFixed(2);
                },
            },

            {
                caption: 'Fund Gap (Rs)',
                value: (content) => {
                    // In some cases approved,utilised and advance are string
                    // In such case remove comma from string and convert to number
                    var utilised;
                    if (typeof content.values.utilised === 'string') {
                        utilised = parseFloat(content.values.utilised.replace(/,/g, ''));
                    } else {
                        utilised = content.values.utilised;
                    }

                    var advance;
                    if (typeof content.values.advance === 'string') {
                        advance = parseFloat(content.values.advance.replace(/,/g, ''));
                    } else {
                        advance = content.values.advance;
                    }

                    var fundgap = (parseFloat(content.total) + advance - utilised).toFixed(2);
                    return fundgap;
                },
            },

            {
                caption: 'Fund Gap (%)',
                value: (content) => {
                    // In some cases approved,utilised and advance are string
                    // In such case remove comma from string and convert to number
                    var utilised;
                    if (typeof content.values.utilised === 'string') {
                        utilised = parseFloat(content.values.utilised.replace(/,/g, ''));
                    } else {
                        utilised = content.values.utilised;
                    }

                    var advance;
                    if (typeof content.values.advance === 'string') {
                        advance = parseFloat(content.values.advance.replace(/,/g, ''));
                    } else {
                        advance = content.values.advance;
                    }

                    var approved;
                    if (typeof content.values.approved === 'string') {
                        approved = parseFloat(content.values.approved.replace(/,/g, ''));
                    } else {
                        approved = content.values.approved;
                    }

                    var fundgapPercent = parseFloat(((parseFloat(content.total) + advance - utilised) / approved) * 100).toFixed(2);

                    return fundgapPercent;
                },
            },

            {
                caption: 'Fund Utilized (%)',
                value: (content) => {
                    // In some cases approved,utilised  are string
                    // In such case remove comma from string and convert to number
                    var utilised;
                    if (typeof content.values.utilised === 'string') {
                        utilised = parseFloat(content.values.utilised.replace(/,/g, ''));
                    } else {
                        utilised = content.values.utilised;
                    }

                    var approved;
                    if (typeof content.values.approved === 'string') {
                        approved = parseFloat(content.values.approved.replace(/,/g, ''));
                    } else {
                        approved = content.values.approved;
                    }

                    var fundUtilised = parseFloat((utilised / approved) * 100).toFixed(2);

                    return fundUtilised;
                },
            },
        ];

        var locations = cityArray;

        headers.forEach((header) => {
            var row = {
                description: header.caption,
                total: 0,
            };

            if (header.caption === 'Fund Utilized (%)') {
                var utilisedamount = null;
                var approvedamount = null;

                locations.forEach((city) => {
                    if (record.report[city]) {
                        var utilised = [],
                            approved = [];

                        // In some cases approved,utilised and advance are string
                        // In such case remove comma from string and convert to number
                        if (typeof record.report[city].values.utilised === 'string') {
                            utilised[city] = parseFloat(record.report[city].values.utilised.replace(/,/g, ''));
                        } else {
                            utilised[city] = record.report[city].values.utilised;
                        }

                        if (typeof record.report[city].values.approved === 'string') {
                            approved[city] = parseFloat(record.report[city].values.approved.replace(/,/g, ''));
                        } else {
                            approved[city] = record.report[city].values.approved;
                        }

                        row[city] = parseFloat(header.value(record.report[city]).replace(/,/g, ''));

                        utilisedamount += Number(utilised[city]);
                        approvedamount += approved[city];

                        row.total = parseFloat((utilisedamount / approvedamount) * 100).toFixed(2);
                    }
                });
            } else if (header.caption === 'Fund Gap (%)') {
                var totalamount = null;
                var advanceamount = null;
                utilisedamount = null;
                approvedamount = null;

                locations.forEach((city) => {
                    if (record.report[city]) {
                        var utilised = [],
                            approved = [],
                            total = [],
                            advance = [];

                        // In some cases approved,utilised and advance are string
                        // In such case remove comma from string and convert to number
                        if (typeof record.report[city].values.utilised === 'string') {
                            utilised[city] = parseFloat(record.report[city].values.utilised.replace(/,/g, ''));
                        } else {
                            utilised[city] = record.report[city].values.utilised;
                        }

                        if (typeof record.report[city].values.approved === 'string') {
                            approved[city] = parseFloat(record.report[city].values.approved.replace(/,/g, ''));
                        } else {
                            approved[city] = record.report[city].values.approved;
                        }

                        total[city] = record.report[city].total;

                        if (typeof record.report[city].values.advance === 'string') {
                            advance[city] = parseFloat(record.report[city].values.advance.replace(/,/g, ''));
                        } else {
                            advance[city] = record.report[city].values.advance;
                        }

                        row[city] = parseFloat(header.value(record.report[city]));

                        utilisedamount += Number(utilised[city]);
                        approvedamount += approved[city];
                        advanceamount += Number(advance[city]);
                        totalamount += total[city];

                        row.total = parseFloat(((totalamount + advanceamount - utilisedamount) / approvedamount) * 100).toFixed(2);
                    }
                });
            } else {
                locations.forEach((city) => {
                    if (record.report[city]) {
                        //Convert string type to number to calculate
                        if (typeof header.value(record.report[city]) === 'string') {
                            row[city] = parseFloat(header.value(record.report[city]).replace(/,/g, ''));
                        } else {
                            row[city] = parseFloat(header.value(record.report[city]));
                        }

                        row.total = (parseFloat(row.total) + parseFloat(row[city])).toFixed(2);
                    }
                });
            }

            arr.push(row);
        });

        setBreakup(arr);

        var exporData = getExportData(arr, columns);

        setExptRecords(exporData);

        Object.keys(record.report).forEach((city) => {
            summary.total += record.report[city].total;

            summary.values['advance'] += parseFloat(record.report[city].values['advance']);

            summary.values['approved'] += parseFloat(record.report[city].values['approved']);

            summary.values['utilised'] += parseFloat(record.report[city].values['utilised']);

            summary.vehicleCount += record.report[city].vehicleCount;
        });

        setSummary({ ...summary });
    }

    function prev() {
        current = current - 1;

        // if (current) {

        // get the ids of the data
        var ids = Object.keys(data);

        // Set the current record as last one
        var record = data[ids[current]];

        setCurrent(current);

        setRecord(record);

        updateSummary(record);

        // }
    }

    function next() {
        current = current + 1;

        // if (current) {

        // get the ids of the data
        var ids = Object.keys(data);

        // Set the current record as last one
        var record = data[ids[current]];

        setCurrent(current);

        setRecord(record);

        updateSummary(record);

        // }
    }

    return (
        <div className="if-status">
            <div className="page-header">
                <Button
                    disabled={current <= 0}
                    size={'small'}
                    onClick={() => {
                        prev();
                    }}
                >
                    Prev
                </Button>

                <div className="title">
                    <h1>
                        {/* {moment(record.created_at, 'dddd, MMMM Do ').format('dddd, MMMM Do ')} */}
                        {record.created_at}
                    </h1>

                    {/* <p>
                    {moment(record.created_at, 'DD/MM/YYYY HH:mm').format('h:mm:ss a')}
                </p> */}
                </div>

                <Button disabled={current >= Object.keys(data).length - 1} onClick={next} size={'small'}>
                    Next
                </Button>

                {expRecord && <ExportReactCSV csvData={expRecord} />}
            </div>

            <div className="page-content card">
                {/* IF Summary Table */}

                <Table
                    // scroll={{ x: true, y: 750 }}
                    columns={columns}
                    dataSource={breakup}
                    size="small"
                    // summary={pageData => {

                    //     if (!pageData.length) { return [] };

                    //     let approved = pageData[0];

                    //     let utilised = pageData[1];

                    //     console.log(pageData);

                    //     let fundGap = pageData[6];

                    //     var fundGapPercent = {};

                    //     let fundUtilisedPercent = {};

                    //     ['kozhikode', 'kannur', 'malappuram', 'total'].forEach((key) => {

                    //         fundGapPercent[key] = (fundGap[key] / approved[key]) * 100;

                    //         fundUtilisedPercent[key] = (utilised[key] / approved[key]) * 100;

                    //     })

                    //     return (
                    //         <>
                    //             <Table.Summary.Row>

                    //                 <Table.Summary.Cell></Table.Summary.Cell>

                    //                 <Table.Summary.Cell>Fund Gap (%)</Table.Summary.Cell>

                    //                 {
                    //                     Object.keys(fundGapPercent).map((key) => {

                    //                         return (<Table.Summary.Cell>
                    //                             {parseFloat(fundGapPercent[key]).toFixed(2)} %
                    //                         </Table.Summary.Cell>)

                    //                     })
                    //                 }
                    //             </Table.Summary.Row>

                    //             <Table.Summary.Row>

                    //                 <Table.Summary.Cell></Table.Summary.Cell>

                    //                 <Table.Summary.Cell>Fund Utilised (%)</Table.Summary.Cell>

                    //                 {
                    //                     Object.keys(fundUtilisedPercent).map((key) => {

                    //                         return (<Table.Summary.Cell>
                    //                             {parseFloat(fundUtilisedPercent[key]).toFixed(2)} %
                    //                         </Table.Summary.Cell>)

                    //                     })
                    //                 }
                    //             </Table.Summary.Row>

                    //         </>
                    //     );
                    // }}
                />

                {/* <div className="card box">

                <div className="left">

                    <h1 className="city">
                        All
                    </h1>

                    <p>
                        {summary.vehicleCount} Vehicles
                    </p>


                    <div>
                        <h3>
                            Stock Value
                        </h3>

                        <h2>
                            <Rupee value={summary.total}></Rupee>
                        </h2>
                    </div>

                </div>

                <div className="right">

                    <div className>

                        <div>
                            <h3>
                                Fund Gap
                            </h3>

                            <h2>

                                <Rupee value={(parseFloat(summary.values.advance) + parseFloat(summary.total)) - (parseFloat(summary.values.utilised))}></Rupee>
                            </h2>
                        </div>


                        <div className='usage'>

                            <Progress percent={Math.round((summary.values.utilised) / (summary.values.approved) * 100)} />

                            <p>

                                <Rupee value={summary.values.utilised}></Rupee> Utilised / <Rupee value={summary.values.approved}></Rupee> Approved
                            </p>
                        </div>

                    </div>
                    <div className="stats">

                        <div>
                            <h3>
                                Available
                            </h3>

                            <h2>
                                <Rupee value={summary.values.approved - summary.values.utilised}></Rupee>
                            </h2>
                        </div>



                        <div>
                            <h3>
                                Advance
            </h3>

                            <h2>




                            
                                <Rupee value={summary.values.advance}></Rupee>

                            </h2>
                        </div>


                    </div>




                </div>


            </div> */}

                {/* Breakup by City */}

                {/* {
                Object.keys(record.report).map((id) => {

                    let city = record.report[id];

                    console.log(city, id);

                    let gap = (parseFloat(city.total) + parseFloat(city.values.advance)) - parseFloat(city.values.utilised);

                    console.log(city.total, city.values.advance, city.values.utilised, gap);



                    return (<div className="card box">

                        <div className="left">

                            <h1 className="city">
                                {id}
                            </h1>

                            <p>
                                {city.vehicleCount} Vehicles
                            </p>


                            <div>
                                <h3>
                                    Stock Value
                                </h3>

                                <h2>
                                    <Rupee value={city.total}></Rupee>
                                </h2>
                            </div>

                        </div>

                        <div className="right">

                            <div className>

                                <div>
                                    <h3>
                                        Fund Gap
                                    </h3>

                                    <h2>
                                        <Rupee value={gap}></Rupee>
                                    </h2>
                                </div>


                                <div className='usage'>

                                    <Progress percent={Math.round((city.values.utilised) / (city.values.approved) * 100)} />

                                    <p>
                                        <Rupee value={city.values.utilised}></Rupee> Utilised / <Rupee value={city.values.approved}></Rupee> Approved
                                    </p>
                                </div>

                            </div>
                            <div className="stats">

                                <div>
                                    <h3>
                                        Available
                                    </h3>

                                    <h2>
                                        <Rupee value={city.values.approved - city.values.utilised}></Rupee>
                                    </h2>
                                </div>



                                <div>
                                    <h3>
                                        Advance
                                    </h3>

                                    <h2>
                                        <Rupee value={city.values.advance}></Rupee>
                                    </h2>
                                </div>


                            </div>




                        </div>


                    </div>)

                })
            } */}

                {/* Breakup by city Ends */}
            </div>
        </div>
    );
}
